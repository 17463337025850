let environment = {
    ab_url: 'https://ab.proxy.rayobyte.com',
    whmcs_url: 'https://billing.rayobyte.com',
    easy_auth_pass: "/hosting/modules/addons/easy_auth/do.php",	
    backend_url: 'htps://backend.dashboard.proxy.rayobyte.com',
    captcha_key: '6LfTRkkqAAAAAC2WAofph4whJ2O7fU8cr1fZkyO-',
    charge_bee_account_domain: 'sprious-test',
    rebilly_pub_key: 'pk_sandbox_GpaGfGNizotn7nH0dRhmesldB6C3MfVPMvXwe6X',
    mvp_dashboard_url: 'https://dashboard.staging.rayobyte.com',
    tos_url: "https://www.rayobyte.com/terms-of-use.html",
    aup_url: "https://rayobyte.com/tos-and-aup/",
    pp_url: "https://rayobyte.com/privacy-policy/",
};
export default environment

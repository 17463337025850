<template>
  <div class="main-wrapper">
    <Header></Header>

    <v-row class="remove-top-margin custom-align-center">
      <v-col md="9" class="d-flex align-content-center flex-wrap content-wrapper" no-gutters>
        <div class="context-wrapper">
          <TrialInfoBlock></TrialInfoBlock>
        </div>

        <!-- Promo blocks -->
        <div class="promo-blocks-wrapper" v-if="showPromoBlocks && step_element === 1">
          <div class="promo-block">
            <h2>Get 15% off on All static DC proxies with coupon: <span class="coupon-code">New15</span></h2>            
            <v-btn
              @click="applyNew15Promo"
              class="promo-btn"
            >
              Get My 15% Off
            </v-btn>
          </div>

          <div class="promo-block">
            <h2>Get 25% off on All ISP proxies with coupon: <span class="coupon-code">New25</span></h2>            
            <v-btn
              @click="applyNew25Promo"
              class="promo-btn"
            >
              Get My 25% Off
            </v-btn>
          </div>
        </div>

        <div class="context-wrapper">
          <h1>Purchase</h1>
        </div>

        <div class="stepper-wrapper">
          <v-stepper v-model="step_element" flat class="stepper-block">
            <v-stepper-header class="stepper-header">
              <template v-for="item in steps">
                <v-stepper-step
                    :key="`${item.id}-step`"
                    :editable="checkCompleteStep(item.id)"
                    :edit-icon="edit_icon"
                    :step="item.id"
                    :rules="[value => !!item.valid]"
                    color="#07B6BF"
                    :complete="checkCompleteStep(item.id)"
                    @click="validateCompleteStep"
                >
                  <div class="step-item">{{ item.title }}</div>
                </v-stepper-step>

                <v-icon
                    :key="item.id"
                    v-if="item.id !== steps.length"
                >navigate_next</v-icon>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content :key="`1-content`" :step="1" class="tab-item-container">

                <div class="package-section-time">
                  <h3 class="section-title">
                    <span class="number-section">1</span>
                    Proxy type
                    <a href="https://rayobyte.com/products" target="_blank" class="section-support-link">
                      What is it?
                    </a>
                  </h3>
                  <v-row class="category-list-wrapper">
                    <div v-for="item in proxy_types"
                         v-if="proxy_types_for_ui.indexOf(item.name) !== -1 "
                         class="btn-category-wrapper"
                    >
                      <v-btn block v-if="!important"
                             depressed
                             :class="{ proxy_type_text: item.name === 'static-res' || item.name === 'semi-3' }"
                             :disabled="!item.show"
                             :dark="item.isSelected"
                             @click.prevent="selectProxy(item.id)"
                      >
                        {{ item.title }}
                      </v-btn>
                      <v-btn block v-if="important"
                             depressed
                             :disabled="item.name != 'dedicated'"
                             :dark="item.isSelected || item.name == 'dedicated'"
                             @click.prevent="selectProxy(item.id)"
                      >
                        {{ item.title }}
                      </v-btn>
                    </div>
                    <div class="btn-category-wrapper">
                      <v-btn block
                             :disabled="complex_ipv6_button.isDisabled"
                             :dark="complex_ipv6_button.isSelected"
                             depressed
                             @click.prevent="selectComplexIPv6(complex_ipv6_button.proxyTypeId)"
                      >
                        IPv6
                      </v-btn>
                    </div>
                  </v-row>

                  <v-text-field
                      v-if="user_info.order_proxy_type.errors.length > 0"
                      :error="user_info.order_proxy_type.errors.length > 0"
                      :error-messages="user_info.order_proxy_type.errors"
                      required
                      class="custom-error-handler"
                  ></v-text-field>

                </div>
                <div class="package-section-time">
                  <h3 class="section-title"><span class="number-section">2</span> Select location</h3>
                  <v-autocomplete
                      v-model="countries_model"
                      :items="countries"
                      :error="user_info.order_location.errors.length > 0"
                      :error-messages="user_info.order_location.errors"
                      :menu-props="{ overflowY: true, offsetOverflow: true, closeOnContentClick: true, }"
                      item-text="name"
                      item-value="name"
                      label="Pick Proxy Country"
                      outlined
                      clearable
                      placeholder="Pick Proxy Country"
                      @click:clear="clearSelect"
                      class="custom-select-country"
                  >
                    <template v-slot:selection="data">
                      <div
                          v-bind="data.attrs"
                          class="option-country-wrapper"
                      >
                        <v-icon class="flag-icon" :class="`flag-icon-${data.item.iso}`"></v-icon>
                        {{data.item.name}}
                      </div>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item
                          :disabled="!data.item.show"
                          @click.prevent="selectCountry(data.item.id)"
                      >
                        <v-card
                            flat
                            :disabled="!data.item.show"
                            class="custom-list-style"
                        >
                          <v-icon class="flag-icon" :class="`flag-icon-${data.item.iso}`"></v-icon>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-card>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="package-section-time">
                  <h3 class="section-title"><span class="number-section">3</span> Number of proxies</h3>
                  <v-row class="number-block-wrapper">
                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                    >
                      <div class="number-block">
                        <v-menu
                            v-model="expand_availability"
                            :close-on-content-click="false"
                            :content-class="'custom-note-content'"
                            offset-x
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="order.proxy_number"
                                :error="order.proxy_number_errors.length > 0"
                                :error-messages="order.proxy_number_errors"
                                :step="1"
                                type="number"
                                single-line
                                required
                                class="custom-input-form"
                                @input="calculate_price"
                                @change="calculate_price"
                            ></v-text-field>
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    v-show="expand_availability"
                                    class="information-icon"
                            >error_outline</v-icon>
                          </template>
                          <div class="note-modal-wrapper">
                            <div class="note-modal-content">
                              <div class="text-h6">Note!</div>
                              <div class="availability-holder">
                                Inventory is low - we may only be able to partially fill your order today,
                                and the remainder will be filled as soon as possible (may take up to a few weeks).
                                To get a more accurate timeline either before or after your order,
                                please reach out to our Customer
                                Success team at <a href="mailto:support@rayobyte.com">support@rayobyte.com</a>.
                              </div>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </v-col>
                    <PackageCaptionList></PackageCaptionList>
                  </v-row>
                </div>
                <div class="package-section-time">
                  <h3 class="section-title"><span class="number-section">4</span> Subscription period</h3>
                  <div class="period-list-wrapper">
                    <div v-for="item in periods" class="period-item">
                      <v-btn block
                             depressed
                             :dark="item.isSelected"
                             :disabled="!item.show"
                             @click.prevent="selectPeriod(item.id)"
                             class="period-btn"
                      >
                        <span class="title-val">{{ item.title }}</span>
                        <span v-if="item.off > 0" class="discount-val">
                          {{ item.off }}%
                        </span>
                      </v-btn>
                    </div>
                  </div>
                  <v-text-field
                      v-if="user_info.order_proxy_period.errors.length > 0"
                      :error="user_info.order_proxy_period.errors.length > 0"
                      :error-messages="user_info.order_proxy_period.errors"
                      required
                      class="custom-error-handler"
                  ></v-text-field>
                </div>
              </v-stepper-content>
              <v-stepper-content :key="`2-content`" :step="2" class="tab-item-container">
                <div class="account-step-wrapper">
                  <h3 class="section-title">Enter your contact information</h3>
                  <div class="account-button-blocks">
                    <button>I don't have an account</button>
                    <a href="https://rayobyte.com/proxy/dashboard/login-type" target="_blank">I have an account</a>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>Email</label>
                      </div>
                      <v-text-field
                          v-model="user_info.email.value"
                          :error="user_info.email.errors.length > 0"
                          :error-messages="user_info.email.errors"
                          label="Email"
                          single-line
                          required
                          class="custom-input-form"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>Phone number (<span>optional</span>)</label>
                      </div>
                      <v-text-field
                          v-model="user_info.phone_number.value"
                          :error="user_info.phone_number.errors.length > 0"
                          :error-messages="user_info.phone_number.errors"
                          label="Phone number"
                          single-line
                          class="custom-input-form"
                      >

                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>Password</label>
                      </div>
                      <v-text-field
                          v-model="user_info.password.value"
                          :error="user_info.password.errors.length > 0"
                          :error-messages="user_info.password.errors"
                          label="Password"
                          type="password"
                          single-line
                          required
                          class="custom-input-form"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>Confirm Password</label>
                      </div>
                      <v-text-field
                          v-model="user_info.confirm_password.value"
                          :error="user_info.confirm_password.errors.length > 0"
                          :error-messages="user_info.confirm_password.errors"
                          label="Confirm Password"
                          type="password"
                          single-line
                          required
                          class="custom-input-form"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div class="check-terms-item">
                        <div class="birthday-handler-wrapper">
                          <v-checkbox
                              v-model="user_info.date.value"
                              :label="`I certify that I am over the age of 18`"
                              :error="user_info.date.errors.length > 0"
                              :error-messages="user_info.date.errors"
                              @click="acceptBirthdayValue"
                              class="custom-checkbox"
                          ></v-checkbox>
                          <v-tooltip
                              right
                              content-class="custom-tooltip-wrapper"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div class="modal-info-wrapper">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="information-icon"
                                >error_outline</v-icon>
                              </div>
                            </template>
                            <div class="custom-tooltip-block">
                              <p>
                                According to our TOS, you must be 18 years or older to purchase. Thank you for your
                                understanding!
                              </p>
                            </div>
                          </v-tooltip>
                        </div>
                      </div>
                      <div class="check-terms-item">
                        <v-checkbox
                            v-model="user_info.agreement.value"
                            :label="`I have read and agree to the `"
                            :error="user_info.agreement.errors.length > 0"
                            :error-messages="user_info.agreement.errors"
                            class="custom-checkbox"
                        ></v-checkbox>

                        <span class="terms-links">
                          <a :href="env.aup_url" target="_blank">Terms of Service</a>,
                          <a :href="env.aup_url" target="_blank">Acceptable Use Policy</a> and
                          <a :href="env.pp_url" target="_blank">Privacy Policy</a>
                        </span>
                        <v-tooltip
                            left
                            content-class="custom-left-tooltip-wrapper"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div class="modal-info-wrapper">
                              <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="information-icon"
                              >error_outline</v-icon>
                            </div>
                          </template>
                          <div class="custom-tooltip-block">
                            <p>
                              Our policies have changed in compliance with the General Data Protection Regulation
                              (GDPR). Please read all documents listed here.
                            </p>
                          </div>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-stepper-content>
              <v-stepper-content :key="`3-content`" :step="3" class="tab-item-container">
                <div class="billing-info-wrapper">
                  <h3 class="section-title">Name</h3>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>First name</label>
                      </div>
                      <v-text-field
                          v-model="user_info.firstName.value"
                          :error="user_info.firstName.errors.length > 0"
                          :error-messages="user_info.firstName.errors"
                          label="First name"
                          single-line
                          required
                          class="custom-input-form"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div class="custom-input-label">
                        <label>Last name</label>
                      </div>
                      <v-text-field
                          v-model="user_info.lastName.value"
                          :error="user_info.lastName.errors.length > 0"
                          :error-messages="user_info.lastName.errors"
                          label="Last name"
                          single-line
                          class="custom-input-form"
                      >

                      </v-text-field>
                    </v-col>
                  </v-row>
                  <div class="address-section-wrapper">
                    <h3 class="section-title">Address</h3>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <div class="custom-input-label">
                          <label>Company name (<span>optional</span>)</label>
                        </div>
                        <v-text-field
                            v-model="user_info.companyName.value"
                            :error="user_info.companyName.errors.length > 0"
                            :error-messages="user_info.companyName.errors"
                            label="Company name"
                            single-line
                            required
                            class="custom-input-form"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <div class="custom-input-label">
                          <label>Street address</label>
                        </div>
                        <v-text-field
                            v-model="user_info.streetAddress.value"
                            :error="user_info.streetAddress.errors.length > 0"
                            :error-messages="user_info.streetAddress.errors"
                            label="Street address"
                            single-line
                            required
                            class="custom-input-form"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <div class="custom-input-label">
                          <label>Street address 2 (<span>optional</span>)</label>
                        </div>
                        <v-text-field
                            v-model="user_info.streetAddress2.value"
                            :error="user_info.streetAddress2.errors.length > 0"
                            :error-messages="user_info.streetAddress2.errors"
                            label="Street address 2"
                            single-line
                            required
                            class="custom-input-form"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <div class="custom-input-label">
                          <label>City</label>
                        </div>
                        <v-text-field
                            v-model="user_info.city.value"
                            :error="user_info.city.errors.length > 0"
                            :error-messages="user_info.city.errors"
                            label="City"
                            single-line
                            required
                            class="custom-input-form"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <div class="custom-input-label">
                          <label>Postcode</label>
                        </div>
                        <v-text-field
                            v-model="user_info.postcode.value"
                            :error="user_info.postcode.errors.length > 0"
                            :error-messages="user_info.postcode.errors"
                            label="Postcode"
                            single-line
                            required
                            class="custom-input-form"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <div class="custom-input-label">
                          <label>Country</label>
                        </div>
                        <v-select
                            @input="changedCountry"
                            v-model="user_info.country.value"
                            :error="user_info.country.errors.length > 0"
                            :error-messages="user_info.country.errors"
                            :items="home_country_items"
                            single-line
                            label="Country"
                            class="custom-select-form"
                            required
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <div class="custom-input-label">
                          <label>State/Territory</label>
                        </div>
                        <v-select
                            v-if="selectedCountry && selectedCountry.states"
                            v-model="user_info.state.value"
                            :items="selectedCountry.states"
                            :error="user_info.state.errors.length > 0"
                            :error-messages="user_info.state.errors"
                            label="State/Territory"
                            single-line
                            class="custom-select-form"
                            required
                        ></v-select>

                        <v-text-field
                            v-else
                            v-model="user_info.state.value"
                            :error="user_info.state.errors.length > 0"
                            :error-messages="user_info.state.errors"
                            label="State/Territory"
                            single-line
                            class="custom-input-form"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content :key="`4-content`" :step="4" class="tab-item-container summary-tab">
                <div class="summary-block-wrapper">
                  <h3 class="section-title">You order</h3>
                  <div class="summary-order-items">
                    <v-list-item>
                      <v-list-item-content v-model="order.package_type">Package type</v-list-item-content>
                      <v-list-item-content class="justify-end">
                        {{ order.package_type === '' ? 'Not Selected' : order.package_type }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Proxy type</v-list-item-content>
                      <v-list-item-content class="justify-end text-right">
                        {{ order.proxy_type === '' ? 'Not Selected' : proxy_type_titles[order.proxy_type] }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Location</v-list-item-content>
                      <v-list-item-content class="justify-end text-right">
                        {{ order.location_name === '' ? 'Not Selected' : order.location_name }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Number of proxies</v-list-item-content>
                      <v-list-item-content class="justify-end">
                        {{ order.proxy_number === 0 ? 'Not Selected' : order.proxy_number }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Subscription period</v-list-item-content>
                      <v-list-item-content class="justify-end">
                        {{ order.period === '' ? 'Not Selected' : periods_titles[order.period].label }}
                      </v-list-item-content>
                    </v-list-item>

                    <div v-show="promo_code_action.button"
                         class="have-promo-btn"
                         @click="havePromo"
                    >I have a Promo Code!</div>
                    <div v-show="promo_code_action.input_content" class="promo-code-wrapper">
                      <div class="promo-list-item">
                        <input
                            @input="checkPromoInput"
                            v-model="order.promo"
                            type="text"
                            :disabled="order.subscription_type === 'trial'"
                            placeholder="Promo Code"
                            class="apply-promo-input"
                        >
                        <div class="error-promo-wrapper">
                          <v-alert
                              v-model="promoAlert"
                              close-text="Close message"
                              dismissible
                              outlined
                              icon="warning_amber"
                              type="warning"
                              @input="clear_promo_field"
                              class="promo-error-block"
                          >
                            Sorry, that promo code is not valid
                          </v-alert>
                        </div>
                      </div>
                      <div class="promo-list-item-btn justify-end">
                        <v-btn
                            @click="applyPromo"
                            depressed
                            color="#07B6BF"
                            dark
                            block
                            :disabled="!ipv6_check_total && order.product === null || order.subscription_type === 'trial' || order.proxy_number_errors.length > 0"
                            :dark="ipv6_check_total || order.product !== null && order.subscription_type !== 'trial' && this.order.proxy_number_errors.length === 0"
                            class="apply-promo-btn"
                        >Apply
                        </v-btn>
                      </div>
                    </div>

                    <!-- Original price (only show when there's a discount) -->
                    <v-list-item v-if="order.discount > 0 && order.promo_is_valid && order.product">
                      <v-list-item-content>Original price</v-list-item-content>
                      <v-list-item-content class="justify-end">
                        ${{ order.subtotal }}
                      </v-list-item-content>
                    </v-list-item>

                    <!-- Discount amount (only show when there's a discount) -->
                    <v-list-item v-if="order.discount > 0 && order.promo_is_valid && order.product">
                      <v-list-item-content>Promo discount ({{ order.promo }})</v-list-item-content>
                      <v-list-item-content class="justify-end text-discount">
                        -${{ order.discount }}
                      </v-list-item-content>
                    </v-list-item>

                    <!-- Total (always show) -->
                    <v-list-item class="order-result-item">
                      <v-list-item-content>Total</v-list-item-content>
                      <v-list-item-content class="justify-end">
                        ${{ order.total }}
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <div class="payment-block-wrapper">
                    <h3 class="section-title">Payment Type</h3>
                    <v-row class="payment-btn-wrapper">
                      <v-col cols="12" sm="6" md="6" class="payment-item">
                        <v-btn block
                               depressed
                               :error="user_info.paymethod.errors.length > 0"
                               :error-messages="user_info.paymethod.errors"
                               :dark="user_info.paymethod.value === 'stripe'"
                               @click.prevent="selectPayment('stripe')"
                        >
                          <img src="@/assets/credit_card_icon.png" alt="Credit Card">
                          Credit Card
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="payment-item">
                        <v-btn block
                               depressed
                               v-model="paypalAlert"
                               :error="user_info.paymethod.errors.length > 0"
                               :error-messages="user_info.paymethod.errors"
                               :dark="user_info.paymethod.value === 'paddle'"
                               @click.prevent="selectPayment('paddle')"
                        >
                          <img src="@/assets/credit_card_icon.png" alt="PayPal">
                          Pay with PayPal
                        </v-btn>
                      </v-col>
                    </v-row>
                    <div class="payment-caption-block">
                        <v-alert
                                v-model="paypalAlert"
                                outlined
                                icon="warning_amber"
                                type="warning"
                                class="promo-error-block"
                        >
                            Additional fees (up to 5%) may apply when using PayPal payments
                        </v-alert>
                      <em>You will be redirected to provide details after clicking 'Buy Now'</em>
                    </div>
                  </div>
                  <div class="order-btn-wrapper">
                    <div class="stepper-btn-wrapper">
                      <v-btn class="step-btn prev-step-btn" @click="prevStep">
                        <v-icon>arrow_back_ios</v-icon> Back
                      </v-btn>
                    </div>
                    <v-btn block
                           depressed
                           color="#07B6BF"
                           :dark="!ipv6_check_total &&  order.product !== null && order.proxy_number_errors.length === 0"
                           :disabled="ipv6_check_total || order.product === null || order.proxy_number_errors.length > 0"
                           @click.prevent="acceptOrder"
                           class="order-btn"
                    >{{ order.subscription_type === 'trial' ? 'Get trial' : 'Buy now' }}
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <div v-if="step_element < 4" class="stepper-btn-wrapper">
            <v-btn
                v-if="step_element > 1"
                class="step-btn prev-step-btn"
                @click="prevStep">
              <v-icon>arrow_back_ios</v-icon> Back
            </v-btn>

            <v-btn class="step-btn next-step-btn" color="#07B6BF" @click="nextStep">
              Next step <v-icon>navigate_next</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-if="step_element < 4" md="3" cols="12" class="sticky-holder remove-top-margin sticky-sidebar-wrapper">
        <vueStickySidebar
            containerSelector=".sticky-holder"
            :topSpacing="top_sticky"
        >
          <v-card flat>
            <v-card-title class="subheading font-weight-bold">
              Your order
            </v-card-title>
            <div class="summary-order-items">
              <v-list-item>
                <v-list-item-content v-model="order.package_type">Package type</v-list-item-content>
                <v-list-item-content class="justify-end">
                  {{ order.package_type === '' ? 'Not Selected' : order.package_type }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Proxy type</v-list-item-content>
                <v-list-item-content class="justify-end text-right">
                  {{ order.proxy_type === '' ? 'Not Selected' : proxy_type_titles[order.proxy_type] }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Location</v-list-item-content>
                <v-list-item-content class="justify-end text-right">
                  {{ order.location_name === '' ? 'Not Selected' : order.location_name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Number of proxies</v-list-item-content>
                <v-list-item-content class="justify-end">
                  {{ order.proxy_number === 0 ? 'Not Selected' : order.proxy_number }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Subscription period</v-list-item-content>
                <v-list-item-content class="justify-end">
                  {{ order.period === '' ? 'Not Selected' : periods_titles[order.period].label }}
                </v-list-item-content>
              </v-list-item>

              <!-- Original price (only show when there's a discount) -->
              <v-list-item v-if="order.discount > 0 && order.promo_is_valid && order.product">
                <v-list-item-content>Original price</v-list-item-content>
                <v-list-item-content class="justify-end">
                  ${{ order.subtotal }}
                </v-list-item-content>
              </v-list-item>

              <!-- Discount amount (only show when there's a discount) -->
              <v-list-item v-if="order.discount > 0 && order.promo_is_valid && order.product">
                <v-list-item-content>Promo discount2 ({{ order.promo }})</v-list-item-content>
                <v-list-item-content class="justify-end text-discount">
                  -${{ order.discount }}
                </v-list-item-content>
              </v-list-item>

              <!-- Total (always show) -->
              <v-list-item class="order-result-item">
                <v-list-item-content>Total</v-list-item-content>
                <v-list-item-content class="justify-end">
                  ${{ order.total }}
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
        </vueStickySidebar>
      </v-col>
    </v-row>

    <v-row>
      <v-dialog
          v-model="loading"
          persistent
          width="300"
      >
        <v-card
            color="#07B6BF"
            dark
        >
          <v-card-text>
            Please stand by
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- modal for Neverbounce -->
      <v-row justify="center">
        <v-dialog
            v-model="show_neverbounce"
            persistent
            max-width="600"
        >
          <v-card class="neverbounce-alet-wrapper">
            <v-icon class="information-icon">warning_amber</v-icon>
            <v-card-title class="headline">
              Email status
            </v-card-title>
            <br>
            <v-card-text class="alert-content">
              <p>
                Hi there! Due to an increased amount of botting activity, we recently
                implemented
                an email verification service, and unfortunately we are not able to verify
                the address you entered.
              </p>
              <p>
                Don't fret! It does make mistakes every once in a while, so if you are sure the
                email address is entered correctly and continue to receive this message, please
                contact a member of our support team through the widget in the bottom left
                corner
                of your screen. Or you can email us at
                <a href="support@rayobyte.com" style="color: #07B6BF">support@rayobyte.com</a>.
                They will get you set up with your account pronto!
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="#07B6BF"
                  text
                  @click="show_neverbounce = !show_neverbounce"
              >
                Close
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- end of modal for Neverbounce  -->

      <!-- modal for TFA code -->
      <v-row justify="center">
        <v-dialog
            v-model="show_tfa"
            persistent
            max-width="450"
        >
          <v-card class="tfa-block-wrapper">
            <v-card-text>
              <div class="tfa-icon-wrapper">
                <img src="@/assets/info_icon.svg" alt="info">
              </div>
              <div class="tfa-title">Verify E-Mail</div>
              <p class="tfa-content">
                We have sent one-time password (OTP) on E-Mail:<br>
                <strong>{{ user_info.email.value }}</strong>
              </p>
              <v-text-field
                  v-model="tfa_code.value"
                  label="One-Time Password"
                  :error="tfa_code.errors.length > 0"
                  :error-messages="tfa_code.errors"
                  single-line
                  class="custom-input-form"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="tfa-btn-wrapper">
              <div class="tfa-btn-items">
                <v-btn
                    color="#07B6BF"
                    text
                    @click="check_tfa"
                    class="confirm-btn"
                >
                  Confirm
                </v-btn>
                <v-btn
                    color=""
                    text
                    @click="request_tfa"
                    class="resend-btn"
                >
                  Resend
                </v-btn>
                <v-icon
                    class="custom-close-modal"
                    @click="hide_tfa_input"
                >highlight_off</v-icon>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- end of modal for TFA code  -->
    </v-row>
  </div>

</template>

<script>

import ListHeadingPurchase from '../../components/ui/ListHeadingPurchase'
import SimpleInput from '../../components/ui/SimpleInput'
import countries_bulk from '../../helpers/countries'
import validator from '../../helpers/validator/index'
import vueStickySidebar from "vue-sticky-sidebar";
import axios from "axios";
import Env from "../../../env.js";
import Vue from 'vue'
import {mapState} from 'vuex'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import Header from "@/components/ui/Header";
import TrialInfoBlock from "@/components/ui/TrialInfoBlock";
import Account from "@/components/ui/tabs/Account";
import PackageCaptionList from "@/components/ui/PackageCaptionList";

Vue.use(VueReCaptcha, {siteKey: Env.captcha_key});

countries_bulk.sort(function (item, nextItem) {
  if (item.name > nextItem.name) {
    return 1;
  }
  if (item.name < nextItem.name) {
    return -1;
  }
  // a должно быть равным b
  return 0;
});
let countries_titles = {};
countries_bulk.forEach(function (item) {
  countries_titles[item.code.toLowerCase()] = item.name;
});

export default {
  data() {
    return {
      env: Env,
      plan: 'purchase',
      products: [],
      shopping: '0',
      package: '',
      top_sticky: 24,
      payment: '1',
      agreement: 0,
      package_options: 0,
      loading: true,
      show_calculator: false,
      important: false,
      promoAlert: false,
      paypalAlert: false,
      dialog_ip6: false,
      show_tfa: false,
      ipv6_check_total: true,
      ipv6_check_total_warning: false,
      show_neverbounce: false,
      tfa_code: {value: '', errors: [], rules: ['required', {rule: "min", option: 3}]},
      proxy_number_errors: [],
      purposes: [
        {text: 'Please select main purpose for using proxies', value: 1},
        {text: 'Web scraping/Data collection', value: 2},
        {text: 'Shopping/E-commerce price monitoring', value: 3},
        {text: 'Social media monitoring', value: 4},
        {text: 'Security and privacy', value: 5},
        {text: 'Brand protection', value: 6},
        {text: 'Web site testing', value: 7},
        {text: 'Surveys', value: 8},
        {text: 'Sneakers/Shoes', value: 8},
        {text: 'Advertising and verifications', value: 9},
        {text: 'SEO', value: 10},
        {text: 'Web browsing', value: 11},
        {text: 'Educational purposes', value: 12},
        {text: 'Other', value: 13},
      ],
      filtered_products: [],
      countries: [
        // {id: 1, name: 'USA', iso: "us", isSelected: false},
        // {id: 2, name: 'Germany', iso: "de", isSelected: false},
        // {id: 3, name: 'Brazil', iso: "br", isSelected: false},
        // {id: 4, name: 'UK', iso: "gb", isSelected: false},
      ],
      country_titles: countries_titles,
      subscription_types: [
        {id: 1, title: 'Purchase', isSelected: false, value: 'purchase'},
        // {id: 2, title: 'Trial', isSelected: false, value: 'trial'},
      ],
      proxy_types: [
        // {id: 1, title: 'Dedicated', isSelected: false, name: 'dedicated'},
        // {id: 2, title: 'Semi-Dedicated', isSelected: false, name: 'semi-3'},
        // {id: 3, title: 'Rotating', isSelected: false, name: 'rotate'},
      ],
      proxy_type_titles: {
        dedicated: 'Dedicated',
        'semi-3': 'Semi-Dedicated',
        rotate: 'Rotating',
        sneaker: 'Sneaker',
        shopify: 'Shopify',
        _48: '/48 Dedicated',
        _56: '/56 Dedicated',
        _64: '/64 Dedicated',
        'static-res': 'ISP proxies',
        'semi-res': 'Semi-Dedicated ISP',
        'sneaker-static-res': 'Sneaker ISP proxies',

      },
      proxy_types_for_ui: ['dedicated', 'semi-3', 'rotate', 'static-res', 'sneaker-static-res', 'semi-res'],
      proxy_types_for_ui_v6: ['_48', '_56', '_64'],
      package_types: [
        {id: 1, title: 'IPv4', isSelected: false},
        {id: 2, title: 'IPv6', isSelected: false},
      ],
      periods: [],
      // periods: [
      //     {id: 1, title: '1 Month', value: 1, off: 0, isSelected: false, name: 'monthly', show: true},
      //     {id: 2, title: '3 Months', value: 3, off: 5, isSelected: false, name: 'quarterly', show: true},
      //     {id: 3, title: '6 Months', value: 6, off: 15, isSelected: false, name: 'semiannually', show: true},
      //     {id: 4, title: '12 Months', value: 12, off: 20, isSelected: false, name: 'annually', show: true},
      // ],
      periods_titles: {
        monthly: {label: '1 Month', value: 1, off: 0},
        quarterly: {label: '3 Months', value: 3, off: 5},
        semiannually: {label: '6 Months', value: 6, off: 10},
        annually: {label: '12 Months', value: 12, off: 15},
      },
      styleObject: {
        border: '1px solid blue !important'
      },
      days: [],
      fdays: [],
      months: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
      ],
      month: 'January',
      years: [],
      year: 2003,
      day: 1,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      order: {
        subscription_type: '',
        package_type: '',
        proxy_type: '',
        location: '',
        location_name: '',
        proxy_number: 5,
        proxy_number_errors: [],
        period: '',
        total: 0,
        version: '',
        product: null,
        promo: '',
        discount: 0,
        subtotal: 0,
        promo_is_valid: true
      },
      user_info: {
        firstName: {value: '', errors: [], rules: ['required', {rule: "min", option: 3}]},
        lastName: {value: '', errors: [], rules: ['required', {rule: "min", option: 3}]},
        email: {value: '', errors: [], rules: ['required', 'email']},
        password: {value: '', errors: [], rules: ['required', {rule: "min", option: 6}, 'pass']},
        confirm_password: {value: '', errors: [], rules: ['required']},
        country_phone_code: {value: this.getDefaultCountry(), errors: [], rules: ['required']},
        country: {value: this.getDefaultCountryForCountrySelect(), errors: [], rules: ['required']},
        phone_number: {value: '', errors: [], rules: []},
        streetAddress: {value: '', errors: [], rules: ['required']},
        streetAddress2: {value: '', errors: [], rules: []},
        state: {value: '', errors: [], rules: ['required']},
        city: {value: '', errors: [], rules: ['required']},
        companyName: {value: '', errors: [], rules: []},
        postcode: {value: '', errors: [], rules: ['required', {rule: "min", option: 3}]},
        date: {checkbox: false, value: '', errors: [], rules: ['required']},
        purpose_selected: {value: 1, errors: [], rules: ['required']},
        purpose_option: {value: '', errors: [], rules: []},
        paymethod: {value: 'stripe', errors: [], rules: ['required']},
        agreement: {value: false, errors: [], rules: [{rule: "tos", option: true}]},
        other_paymethod: {value: '', errors: [], rules: []},
        order_proxy_type: {value: '', errors: [], rules: ['required']},
        order_location: {value: '', errors: [], rules: ['required']},
        order_proxy_period: {value: '', errors: [], rules: ['required']},
      },
      available_locations: {},
      expand_availability: false,
      tab: null,

      step_element: 1,
      edit_icon: 'done',
      completed_steps: [],
      steps: [
        {id: 1, title: 'Package Option', rules: [v => !!v || "Required."], valid: true},
        {id: 2, title: 'Account', rules: [v => !!v || "Required."], valid: true},
        {id: 3, title: 'Billing Info', rules: [v => !!v || "Required."], valid: true},
        {id: 4, title: 'Summary', rules: [v => !!v || "Required."], valid: true},
      ],
      countries_model: null,
      show_birthday_modal: false,
      complex_ipv6_button: {
        proxyTypeId: 2,
        isSelected: false,
        isDisabled: false
      },
      promo_code_action: {
        button: true,
        input_content: false
      },
      package_option_error: {
        proxy_type: '',
        location: '',
        proxy_number: '',
        period: '',
      },
      showPromoBlocks: false,
    }
  },
  components: {
    PackageCaptionList,
    Account,
    TrialInfoBlock,
    Header,
    ListHeadingPurchase,
    SimpleInput,
    vueStickySidebar
  },
  methods: {
    checkIt() {
      console.log('works');
    },
    //start helper methods
    //----------------------------------------------------------------------------

    checkCompleteStep(stepId) {
      return this.completed_steps.includes(stepId);
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele){
        return ele !== value;
      });
    },
    validateCompleteStep() {
      for(let i = 0; this.completed_steps.length > i; i++) {
        let itemStep = this.completed_steps[i];

        if (itemStep === 1) {

          this.user_info.order_proxy_type.value = this.order.proxy_type;
          this.user_info.order_location.value = this.order.location;
          this.user_info.order_proxy_period.value = this.order.period;

          let stepValidate = this.validateStep({
            'order_proxy_type': this.user_info.order_proxy_type,
            'order_location': this.user_info.order_location,
            'order_proxy_period': this.user_info.order_proxy_period,
          });

          if (this.order.proxy_number_errors.length > 0) {
            stepValidate = true;
          }

          if (stepValidate) {
            this.steps[1].valid = false;
          } else {
            this.steps[1].valid = true;
          }
        } else if (itemStep === 2) {
          let stepValidate = this.validateStep({
            'email': this.user_info.email,
            'password': this.user_info.password,
            'confirm_password': this.user_info.confirm_password,
            'phone_number': this.user_info.phone_number,
            'date': this.user_info.date,
            'agreement': this.user_info.agreement,
          });
          if (stepValidate) {
            this.steps[0].valid = false;
          } else {
            this.steps[0].valid = true;
          }
        } else if (itemStep === 3) {
          let stepValidate = this.validateStep({
            'firstName': this.user_info.firstName,
            'lastName': this.user_info.lastName,
            'streetAddress': this.user_info.streetAddress,
            'city': this.user_info.city,
            'state': this.user_info.state,
            'postcode': this.user_info.postcode,
            'country': this.user_info.country,
          });
          if (stepValidate) {
            this.steps[2].valid = false;
          } else {
            this.steps[2].valid = true;
          }
        }
      }

    },
    validateSteps() {
      let errorValidate;

       if(this.step_element === 1) {
        this.user_info.order_proxy_type.value = this.order.proxy_type;
        this.user_info.order_location.value = this.order.location;
        this.user_info.order_proxy_period.value = this.order.period;

        errorValidate = this.validateStep({
          'order_proxy_type': this.user_info.order_proxy_type,
          'order_location': this.user_info.order_location,
          'order_proxy_period': this.user_info.order_proxy_period,
        });

        if(this.order.proxy_number_errors.length > 0) {
          errorValidate = true;
        }
        if(!errorValidate) {
          this.steps[1].valid = true;
        }

      } else if(this.step_element === 2) {
        errorValidate = this.validateStep({
          'email': this.user_info.email,
          'password': this.user_info.password,
          'confirm_password': this.user_info.confirm_password,
          'phone_number': this.user_info.phone_number,
          'date': this.user_info.date,
          'agreement': this.user_info.agreement,
        });
        if(!errorValidate) {
          this.steps[0].valid = true;
        }
      } else if(this.step_element === 3) {
        errorValidate = this.validateStep({
          'firstName': this.user_info.firstName,
          'lastName': this.user_info.lastName,
          'streetAddress': this.user_info.streetAddress,
          'city': this.user_info.city,
          'state': this.user_info.state,
          'postcode': this.user_info.postcode,
          'country': this.user_info.country,
        });
        if(!errorValidate) {
          this.steps[2].valid = true;
        }
      }

      if(errorValidate) {
        if(this.completed_steps.includes(this.step_element)) {
          this.completed_steps = this.arrayRemove(this.completed_steps, this.step_element);
        }
      } else {
        if(this.step_element !== 4) {
          this.completed_steps.push(this.step_element);
        }
        this.checkCompleteStep(this.step_element);
      }

      return errorValidate;
    },
    delayedCheckLocationAvailability() {
      var internalObj = this;
      setTimeout(function () {
        internalObj.checkLocationAvailability();
      }, 1000);
    },
    nextStep () {
      if(!this.validateSteps()) {
        this.step_element += 1;
        if(this.step_element === 1) {
          this.delayedCheckLocationAvailability()
        }
      }
    },
    prevStep () {
      this.step_element -= 1
    },
    clearSelect() {
      console.log('clear select country');
      this.selectCountry(this.countries_model.id);
      this.countries_model = null;
      this.order.location = '';
      this.order.location_name = '';
    },
    selectPayment(value) {
      if(value) {
        this.user_info.paymethod.value = value;
      }
      if(value === 'paypalbilling') {
          this.paypalAlert = true;
      } else  {
          this.paypalAlert = false;
      }
    },
    infoUserBirthday() {
      this.show_birthday_modal = true;
    },
    acceptBirthdayValue() {
      // this.checkUserBirthday();
      // if(this.user_info.date.errors.length === 0) {
      if(this.user_info.date.checkbox) {
        this.user_info.date.checkbox = false;
        this.user_info.date.value = '';
      } else  {
        this.user_info.date.checkbox = true;
        this.user_info.date.value = this.getSubDate();
      }
      this.show_birthday_modal = false
    },
    setCountrySelectedFromQuery() {
      this.countries.forEach((item) => {
        if(item.isSelected) {
          this.countries_model = item;
        }
      });
    },
    selectComplexIPv6(id) {

      this.proxyTypesReset();

      if (this.complex_ipv6_button.isSelected) {
        this.complex_ipv6_button.isSelected = false;
        this.countries_model = null;
        this.setCountryChoice('');
        this.order.location = '';
        id = 1;
      } else {
        this.complex_ipv6_button.isSelected = true;
        this.setCountryChoice('us');
        this.order.location = 'us'
        this.countries_model = this.getEntityByNameAndId(this.countries, 1);

        this.selectProxy(1);
        this.selectCountry(1);
      }

      let type = this.getEntityByNameAndId(this.package_types, id);
      type = this.getEntityByNameAndId(this.package_types, id);
      this.processItemArray(id, this.package_types);

      if (type.isSelected) {
        this.order.package_type = type.title;
        this.package = type.title
      } else {
        this.order.package_type = 'IPv4';
        this.package = 'IPv4'
      }

      if (this.order.package_type === 'IPv6') {
        this.order.proxy_number = 25;
      } else {
        this.order.proxy_number = 5;
      }

      if (this.order.package_type === '') this.clearSneakers();
      this.affectAllFilters();
      this.calculate_price();
    },

    havePromo() {
      if(this.promo_code_action.button) {
        this.promo_code_action.button = false;
        this.promo_code_action.input_content = true;
      }
    },

    dateChanged() {
      let months = {
        'January': {
          days: 31,
          number: 1,
        },
        'February': {
          days: 28,
          number: 2,
        },
        'March': {
          days: 31,
          number: 3,
        },
        'April': {
          days: 30,
          number: 4,
        },
        'May': {
          days: 31,
          number: 5,
        },
        'June': {
          days: 30,
          number: 6,
        },
        'July': {
          days: 31,
          number: 7,
        },
        'August': {
          days: 31,
          number: 8,
        },
        'September': {
          days: 30,
          number: 9,
        },
        'October': {
          days: 31,
          number: 10,
        },
        'November': {
          days: 30,
          number: 11,
        },
        'December': {
          days: 31,
          number: 12,
        }
      };
      let high_years = [];
      for (let d = 2028; d > 1920; d = d - 4) high_years.push(d);
      this.days = this.fdays.filter((item) => {
        if (this.month === 'February' && high_years.indexOf(this.year) !== -1) {
          return item <= 29;
        }
        return item <= months[this.month].days;
      });
      if (this.day > months[this.month].days) {
        this.day = 1;
      }
      let rdate = this.day < 10 ? '0' + this.day : this.day;
      let rmonth = months[this.month].number < 10 ? '0' + months[this.month].number : months[this.month].number;
      this.user_info.date.value = rdate + '-' + rmonth + '-' + this.year;
      console.log(this.user_info.date.value);
    },
    getSubDate() {
      let d = new Date();
      d.setMonth(d.getMonth() - 216);

      return d.toISOString().substr(0, 10);
    },
    clear_promo_field() {
      this.order.promo = '';
    },
    checkUserBirthday() {
      let birthday = new Date(this.user_info.date.value);
      let currentDay = new Date();
      let years = (currentDay - birthday) / 1000 / 3600 / 24 / 365;

      if (years < 18) {
        this.user_info.date.errors.push('You should be at least 18 years old.');
      } else {
        this.user_info.date.errors = [];
      }
    },
    resetValidation() {
      for (let field in this.user_info) {
        this.user_info[field].errors = [];
      }
    },
    userInfoValidated() {
      let led = true;
      for (let field in this.user_info) {
        if (this.user_info[field].errors.length > 0) led = false;
      }

      return led;
    },
    validateStep(items) {
      let error = false;
      for (let field in items) {
        let errorVal = validator.validate(this.user_info[field].rules, this.user_info[field].value);
        if(errorVal.length !== 0 && !error) {
          error = true;
        }

        this.user_info[field].errors = errorVal;
      }

      if(items.password) {
        if (this.step_element === 2 && items.password.errors.length === 0 &&
            items.confirm_password.errors.length === 0 &&
            items.password.value !== items.confirm_password.value
        ) {
          this.user_info.confirm_password.errors.push('Confirm password not equals password');
          error = true;
        }
      }

      if(this.step_element === 1 && this.order.package_type === 'IPv6' && parseInt(this.order.total) < 10) {
        this.order.proxy_number_errors.push('Please note the minimum IPv6 proxies order amount is $10');
      }

      return error;
    },
    validateForm() {
      for (let field in this.user_info) {
        // console.log(this.user_info[field].value);
        this.user_info[field].errors = validator.validate(this.user_info[field].rules, this.user_info[field].value);
      }
      if (this.user_info.password.errors.length === 0 &&
          this.user_info.confirm_password.errors.length === 0 &&
          this.user_info.password.value !== this.user_info.confirm_password.value
      ) {
        this.user_info.confirm_password.errors.push('Confirm password not equals password')
      }
    },
    getPurpose(value) {
      let result = '';
      this.purposes.forEach((item) => {
        if (item.value === value && item.value !== 1) {
          result = item.text;
        }
      });

      return result;
    },
    checkPromoInput() {
      this.order.promo_is_valid = this.order.promo.length === 0;
    },
    getMaxPurchaseForSubNet64() {
      return this.getMaxPurchase(64);
    },
    getMaxPurchaseForSubNet56() {
      return this.getMaxPurchase(56);
    },
    getMaxPurchase(number) {
      let result = 25;
      this.products.forEach((item) => {
        if (item.meta.ipVersion == 6 && item.meta.ext.subnet !== undefined && item.meta.ext.subnet == number) {
          result = item.meta.ext.maxPurchase;
        }
      });

      return result;
    },
    validate_proxy_number() {

      let max64 = this.getMaxPurchaseForSubNet64();
      if (this.order.package_type === 'IPv4') {
        if (this.order.proxy_number < 5) {
          this.order.proxy_number_errors.push('Minimum purchase of 5 required');
        }
      } else if (this.order.package_type === 'IPv6') {
        if (this.order.proxy_number < 25) {
          this.order.proxy_number_errors.push('Available for purchase from 25 IP address');
        }
        if (this.order.proxy_number > max64) {
          this.order.proxy_number_errors.push('Max available for purchase: ' + max64);
        }
      }
    },
    //calculate order price
    calculate_price() {
      // Reset promo display if no product
      if (!this.order.product) {
        this.order.discount = 0;
        this.order.promo_is_valid = false;
      }

      // Don't proceed if essential data is missing
      if (!this.order.location || !this.order.proxy_type) {
        this.loading = false;
        return;
      }

      console.log("calculating price");
      this.checkLocationAvailability();
      this.order.subtotal = 0;
      this.order.total = 0;
      this.order.proxy_number_errors = [];
      this.validate_proxy_number();

      //set default period for trial subscription
      if (this.order.subscription_type === 'trial') {
        this.setMinAvailablePeriod();
      }
      // console.log(this.getMaxPurchaseForSubNet56());
      if (this.order.location !== '' && this.order.proxy_type !== '' && this.order.proxy_number !== 0
          && this.order.period !== '' && (this.order.subscription_type === 'purchase' ||
              this.order.subscription_type === 'trial') &&
          (this.order.package_type === 'IPv4' || this.order.package_type === 'IPv6')
          && this.poxyNumberValidated()) {
        let product = null;
        if (this.order.package_type === 'IPv6') {
          this.products.forEach((item) => {
            if (item.meta.ipVersion == 6 && this.order.proxy_number_errors.length === 0) {
              if (item.meta.ext.subnet !== undefined && item.meta.ext.subnet == 64) {
                product = item;
              }

            }

          });
        } else {
          this.products.forEach((item) => {
            if (item.meta.ipVersion == 4 && item.meta.category == this.order.proxy_type && item.meta.country == this.order.location) {
              product = item;
            }
          });
        }
        // console.log(product);
        if (product !== null) {
          this.order.product = product;
          let tiers = product.pricingTiersForCycles[this.order.period].tiers;
          let price = 0;
//                        let min = this.getMinNumberForProduct(product);
          let max = this.getMaxNumberForProduct(product);
          // if (this.order.package_type === 'IPv6'){
          //     max = product.meta.ext.maxPurchase;
          // }
          if (this.order.package_type === 'IPv4') {
            if (this.order.proxy_number > max) {
              this.order.proxy_number_errors.push('max available for purchase: ' + max);

              return false;
            }
          }

          tiers.forEach((tier) => {
            if (tier.from <= this.order.proxy_number && this.order.proxy_number <= tier.to) {
              price = tier.price;
            }
          });
          let total = (price * this.order.proxy_number).toFixed(2);
          this.order.subtotal = total;
          this.order.total = total;
        }

      } else {
        this.order.product = null;
      }

      // After calculating base price, apply promo if exists
      if (this.order.promo) {
        this.applyPromo();
      }

      this.checkIpV6PurchaseAmount()
    },
    getMinNumberForProduct(product) {
      let result = 0;
      let tiers = product.pricingTiersForCycles[this.order.period].tiers;
      tiers.forEach((tier) => {
        result = tier.from;
      });
      tiers.forEach((tier) => {
        if (tier.from < result) {
          result = tier.from;
        }
      });

      return result;
    },
    getMinNumberForInput() {

      return this.order.package_type === 'IPv4' ? 5 : 1;
    },
    getMaxNumberForProduct(product) {
      let result = 0;
      let tiers = product.pricingTiersForCycles[this.order.period].tiers;
      tiers.forEach((tier) => {
        result = tier.to;
      });
      tiers.forEach((tier) => {
        if (tier.to > result) {
          result = tier.to;
        }
      });

      return result;
    },
    //invert selection
    processItemArray(id, array) {
      array.forEach(function (type) {
        if (type.id === id) {
          type.isSelected = !type.isSelected
        } else {
          type.isSelected = false
        }
      })
    },
    //get Item from array by id
    getEntityByNameAndId(entity, id) {
      let result;
      entity.forEach(function (item) {
        if (item.id == id) result = item
      });

      return result;
    },
    //get Item from array by name
    getEntityByName(entity, name) {
      let result;
      entity.forEach(function (item) {
        if (item.name === name) result = item
      });

      return result;
    },
    checkIpV6PurchaseAmount() {
      // console.log('order:', this.order);
      this.ipv6_check_total = this.order.package_type === 'IPv6' && this.order.total < 10;
      this.ipv6_check_total_warning = !this.ipv6_check_total;
    },
    //----------------------------------------------------------------------------
    //end of helper methods


    // start filter and processing filter methods
    //----------------------------------------------------------------------------


    //choosing location
    selectCountry(id) {
      this.processItemArray(id, this.countries);
      let country = this.getEntityByNameAndId(this.countries, id);
      this.countries_model = country;

      if (country.isSelected || this.complex_ipv6_button.isSelected) {
        if(country.iso !== 'us') {
          this.complex_ipv6_button.isDisabled = true;
        } else {
          this.complex_ipv6_button.isDisabled = false;
        }
        this.order.location = country.iso;
        this.order.location_name = country.name;
      } else {
        this.complex_ipv6_button.isDisabled = false;
        this.order.location = '';
        this.order.location_name = '';
      }
      this.affectAllFilters();
      this.calculate_price();
      this.checkLocationAvailability();

    },
    //select plan trial or casual purchase
    selectSubscription(id) {
      this.processItemArray(id, this.subscription_types);
      let package_type = this.getEntityByNameAndId(this.subscription_types, id);
      if (package_type.isSelected) {
        this.order.subscription_type = package_type.value;
        this.plan = package_type.value;
        if (package_type.value == 'trial') {
          this.order.proxy_number = 5;
          if (this.order.package_type === 'IPv6') {
            this.order.proxy_number = 25;
          } else {
            this.order.proxy_number = 5;
          }
          this.order.promo = '';
          this.clearSneakers();
        }
      } else {
        this.order.subscription_type = '';
        //this.resetPeriodSelect();
      }
      this.affectAllFilters();
      this.calculate_price();
    },
    //select type of proxy (rotation, dedicated, semi-dedicated)
    selectProxy(id) {
      if (!this.important) {
        if(!this.complex_ipv6_button.isSelected) {
          this.processItemArray(id, this.proxy_types);
        }

        let proxy_type = this.getEntityByNameAndId(this.proxy_types, id);
        if (proxy_type.isSelected || this.complex_ipv6_button.isSelected) {
          this.order.proxy_type = proxy_type.name
        } else {
          this.order.proxy_type = ''
        }
        this.checkLocationAvailability();
        //this.apply_category_choice();
        this.affectAllFilters();
        this.calculate_price();
      }

    },
    //select billing cycle
    selectPeriod(id) {
      this.processItemArray(id, this.periods)
      let period = this.getEntityByNameAndId(this.periods, id)
      if (period.isSelected) {
        this.order.period = period.name
      } else {
        this.order.period = ''
      }
      this.affectAllFilters();
      this.calculate_price();
    },
    //choose IPv4 or IPv6
    selectPackageType(id) {
      let type = this.getEntityByNameAndId(this.package_types, id);
      this.proxyTypesReset();
      if (type.title === 'IPv6') {
        // this.dialog_ip6 = true;
        this.setCountryChoice('us');
        //this.setPlanChoiceWithUpdate('purchase');
        this.order.location = 'us'
      }
      type = this.getEntityByNameAndId(this.package_types, id);
      this.processItemArray(id, this.package_types);

      if (type.isSelected) {
        this.order.package_type = type.title;
        this.package = type.title
      } else {
        this.order.package_type = '';
        this.package = ''
      }

      if (this.order.package_type === 'IPv6') {
        this.order.proxy_number = 25;
      } else {
        this.order.proxy_number = 5;
      }

      // if (this.order.package_type === 'IPv6') {
      //
      //     this.countriesSetForIpv6();
      //     this.cyclesSetForIpv6();
      // } else {
      //     this.countriesReset();
      //     this.cyclesReset();
      // }
      if (this.order.package_type === '') this.clearSneakers();
      this.affectAllFilters();
      this.calculate_price();
    },
    //set all countries to show and unselected
    setMinAvailablePeriod() {
      this.order.period = 'monthly';
      if (this.periods.length > 0) {
        let min = 12;
        this.periods.forEach((period) => {
          if (period.value < min) {
            min = period.value;
            this.order.period = period.name;
          }
        });
      }
    },
    checkLocationAvailability() {
      if (this.order.proxy_type !== '' && this.order.location !== '') {
        if (this.available_locations[this.order.location] !== undefined &&
            this.available_locations[this.order.location][this.order.proxy_type] !== undefined &&
            this.available_locations[this.order.location][this.order.proxy_type] < this.order.proxy_number &&
            this.step_element === 1) {
          this.expand_availability = true;
          // this.$toast.open({
          //     message: 'Inventory is low - we may only be able to partially fill your order today, and the remainder will be filled as soon as possible (may take up to a few weeks)',
          //     position: 'top-right',
          //     type: 'warning'
          // });

        } else {
          this.expand_availability = false;
        }
      }
    },
    //prepare billing cycles list for ipV6
    cyclesSetForIpv6() {
      this.periods = this.periods.map((item) => {
        item.show = item.name === 'monthly';
        item.isSelected = item.name === 'monthly';
        return item;
      });
      this.order.period = 'monthly';
    },
    //prepare countries list for ipV6
    countriesSetForIpv6() {
      this.countries = this.countries.map((item) => {
        item.show = item.iso === 'us';
        item.isSelected = item.iso === 'us';
        return item;
      });
      this.order.location = 'us';
    },
    //unselect all options for sneakers and shopify including checkbox
    clearSneakers() {
      this.package_options = 0;
      this.important = false;
      this.shopping = 0;
      this.countriesReset();
      this.periodsReset();
      this.proxyTypesReset();
      this.calculate_price();
    },
    //set all countries to show and unselected
    countriesReset() {
      this.countries = this.countries.map((item) => {
        item.isSelected = false;
        item.show = true;
        return item;
      });
      this.order.location = '';
    },
    periodsReset() {
      this.periods = this.periods.map((item) => {
        item.isSelected = false;
        item.show = true;
        return item;
      });
      this.order.period = '';
    },
    proxyTypesReset() {
      this.proxy_types = this.proxy_types.map((item) => {
        item.isSelected = false;
        item.show = true;
        return item;
      });
      this.order.proxy_type = '';
    },
    setSneakers() {
      this.countriesReset();
      if (this.shopping == 1) {
        this.order.proxy_type = 'sneaker'
        this.important = true
      }
      if (this.shopping == 2) {
        this.order.proxy_type = 'shopify'
        this.important = true
      }
      this.unselectSneakersRelatedUi();
      this.affectAllFilters();
      this.countries = this.countries.map((item) => {
        if (item.iso == 'us') {
          item.isSelected = true;
        } else {
          item.isSelected = false;
          item.show = false;
        }
        return item;
      });
      this.order.location = 'us';
      this.calculate_price();
    },
    unselectSneakersRelatedUi() {
      // this.countriesReset();
      this.periodsReset();
      this.proxy_types = this.proxy_types.map((item) => {
        item.isSelected = false;
        item.show = true;
        return item;
      });
    },
    //unselect all options for sneakers and shopify
    resetSneakers() {
      if (this.package_options == 0) {
        this.important = false;
        this.shopping = 0;
        this.countriesReset();
        this.periodsReset();
        this.proxyTypesReset();
        this.calculate_price();

      }
      this.affectAllFilters();
      this.calculate_price();
    },
    country_exists(iso) {
      let led = false;
      this.countries.forEach((item) => {

        if (item.iso == iso) led = true;
      });

      return led;
    },
    category_exists(category) {
      let led = false;
      this.proxy_types.forEach((item) => {

        if (item.name == category) led = true;
      });

      return led;
    },
    period_exists(period) {
      let led = false;
      this.periods.forEach((item) => {

        if (item.name == period) led = true;
      });

      return led;
    },
    //get default country
    getDefaultCountryForCountrySelect() {
      let result = 0;
      countries_bulk.forEach(function (item, index) {
        if (item.code == 'US') {
          result = item.code;
        }

      })

      return result
    },
    get_available_products() {
      this.get_categories();
    },
    //build country array and category array
    get_categories() {
      this.countries = [];
      this.proxy_types = [];
      let i = 1;
      let k = 1;

      this.products.forEach((item) => {
        if (item.meta.ipVersion == 4 || item.meta.ipVersion == 6) {
          if (!this.country_exists(item.meta.country) && item.meta.country != null) {
            this.countries.push({
              id: i,
              name: this.country_titles[item.meta.country],
              iso: item.meta.country,
              show: true,
              isSelected: this.order.location == item.meta.country
            });
            i++;
          }
          if (!this.category_exists(item.meta.category)) {
            this.proxy_types.push({
              id: k,
              name: item.meta.category,
              show: true,
              title: this.proxy_type_titles[item.meta.category],
              isSelected: this.order.location == item.meta.country
            });
            k++;
          }
        }

      });
      this.sortProxyTypeThenPutStaticRes();
      this.get_available_periods();

    },
    sortProxyTypeThenPutStaticRes() {
      this.proxy_types.sort(function (item, nextItem) {
        if (item.title > nextItem.title) {
          return 1;
        }
        if (item.title < nextItem.title) {
          return -1;
        }

        return 0;
      });
      let led = false;
      this.proxy_types.forEach((type) => {
        if (type.name === 'static-res') {
          led = true;
        }
      });
      let temp = null;
      if (led) {
        this.proxy_types = this.proxy_types.filter(function (item) {
          if (item.name !== 'static-res') {
            return true;
          } else {
            temp = item;
          }
        });
        this.proxy_types.push(temp);
      }
      this.countries.sort(function (item, nextItem) {
        if (item.name > nextItem.name) {
          return 1;
        }
        if (item.name < nextItem.name) {
          return -1;
        }

        return 0;
      });
    },
    get_available_periods() {
      this.periods = [];
      let j = 1;
      this.products.forEach((item) => {
        if (item.pricingTiersForCycles !== undefined) {
          for (let cycle in item.pricingTiersForCycles) {
            if (!this.period_exists(cycle)) {
              this.periods.push({
                id: j,
                name: cycle,
                show: true,
                title: this.periods_titles[cycle].label,
                off: this.periods_titles[cycle].off,
                value: this.periods_titles[cycle].value,
                isSelected: this.order.period === cycle
              });
              j++;
            }
          }
        }

      });
      this.periods.sort(function (item, nextItem) {
        if (item.value > nextItem.value) {
          return 1;
        }
        if (item.value < nextItem.value) {
          return -1;
        }

        return 0;
      });
    },
    //according user choice
    refresh_periods_state() {
      this.setAllPeriodsNotActive();
      this.products.forEach((item) => {
        if (this.order.location === '' && this.order.proxy_type === '') {
          this.setCycleDisplay(item);
        } else if (this.order.location !== '' && this.order.proxy_type === '') {
          if (item.meta.country === this.order.location) {
            this.setCycleDisplay(item);
          }
        } else if (this.order.location === '' && this.order.proxy_type !== '') {
          if (item.meta.category === this.order.proxy_type) {
            this.setCycleDisplay(item);
          }
        } else if (this.order.location !== '' && this.order.proxy_type !== '') {
          if (item.meta.category === this.order.proxy_type && item.meta.category === this.order.proxy_type) {
            this.setCycleDisplay(item);
          }
        }

      });
    },
    setCycleDisplay(item) {
      if (item.pricingTiersForCycles !== undefined) {
        for (let cycle in item.pricingTiersForCycles) {
          this.periods.forEach((citem) => {
            if (citem.name === cycle) citem.show = true;
          });
        }
      }
    },
    setAllPeriodsNotActive() {
      this.periods.forEach((citem) => {
        citem.show = false;
      });
    },
    //get default country
    getDefaultCountry() {
      let result = 0;
      countries_bulk.forEach(function (item, index) {
        if (item.code == 'US') {
          result = index
        }

      })

      return result
    },
    resetPeriodSelect() {
      this.periods.forEach((item) => {
        item.isSelected = false;
      });
      this.order.period = '';
    },
    amountChanged() {
      this.calculate_price();
    },
    resetPromoValues() {
      this.order.promo = '';
      this.order.discount = 0;
    },
    cyclesReset() {
      this.periods = this.periods.map((item) => {
        item.isSelected = false;
        item.show = true;
        return item;
      });
      this.order.period = '';
    },
    setPeriodMonthly() {
      this.periods.forEach((item) => {
        if (item.name === 'monthly') item.isSelected = true;
      });
      this.order.period = 'monthly';
    },
    //need set show false for not related categories and set off for category choice if it out of scope
    apply_country_choice() {
      this.resetPeriodSelect();
      this.refresh_periods_state();
      if (this.order.location === '') {
        this.proxy_types = this.proxy_types.map((item) => {
          item.show = true;
          return item;
        });
      } else {
        this.proxy_types = this.proxy_types.map((item) => {
          let led = false;
          this.products.forEach((pitem) => {
            if (pitem.meta.country === this.order.location && item.name === pitem.meta.category) {
              led = true;
            }
          });
          item.show = led;

          return item;
        });
      }
    },
    //need set show false for not related countries and set off for location choice if it out of scope
    apply_category_choice() {
      this.resetPeriodSelect();
      this.refresh_periods_state();
      if (this.order.proxy_type === '') {
        this.countries = this.countries.map((item) => {
          item.show = true;
          return item;
        });
      } else {
        this.countries = this.countries.map((item) => {
          let led = false;
          this.products.forEach((pitem) => {
            if (pitem.meta.category === this.order.proxy_type && item.iso === pitem.meta.country) {
              led = true;
            }
          });
          item.show = led;

          return item;
        });
      }
    },
    getProductsAffectedTrialChoice() {
      let result = [];
      if (this.order.subscription_type === 'trial') {
        this.products.forEach((pitem) => {
          if (pitem.trial > 0) result.push(pitem);
        });
      } else {
        return this.products;
      }

      return result;
    },

    //----------------------------------------------------------------------------
    //end of filter and processing filter methods


    // start requesting data via ajax and process data
    //----------------------------------------------------------------------------
    request_tfa: function () {
      return axios.post(Env.ab_url + '/api/_/tfa/send',
          {name: this.user_info.firstName.value, email: this.user_info.email.value})
          .then(({data}) => {
            if (data.result === 'success') {

            }
            return data;
          });
    },
    hide_tfa_input: function () {

      this.show_tfa = false;
      this.tfa_code.value = '';
      this.tfa_code.errors = [];

    },
    check_tfa: function () {
      this.tfa_code.errors = [];
      this.tfa_code.errors = validator.validate(this.tfa_code.rules, this.tfa_code.value);
      if (this.tfa_code.errors.length > 0) return true;
      this.loading = true;
      return axios.post(Env.ab_url + '/api/_/tfa/check',
          {
            name: this.user_info.firstName.value,
            email: this.user_info.email.value,
            code: this.tfa_code.value,
          })
          .then(({data}) => {
            this.loading = false;
            if (data.status === 'success') {
              this.show_tfa = false;
              this.tfa_code.value = '';
              this.tfa_code.errors = [];
              this.acceptOrder();
            } else {
              this.tfa_code.errors = data.error.errors;
              Vue.$toast.open({
                message: 'Code You sent not valid or expired.',
                position: 'top-right',
                type: 'error'
              });
            }
            return data;
          }).catch(() => {
            this.loading = false;
            Vue.$toast.open({
              message: 'Something went wrong. Please try one more time',
              position: 'top-right',
              type: 'error'
            });
          });
    },
    async acceptOrder() {
      await this.$recaptchaLoaded();
      await this.$store.dispatch('setValidationErrors', []);
      this.resetValidation();
      this.validateForm();
      this.checkUserBirthday();
      if (this.userInfoValidated()) {
        let details = {
          email: this.user_info.email.value,
          password: this.user_info.password.value,
          firstName: this.user_info.firstName.value,
          lastName: this.user_info.lastName.value,
          companyName: this.user_info.companyName.value,
          //birthday: this.user_info.date.value,
          address2: this.user_info.streetAddress2.value,
          address1: this.user_info.streetAddress.value,
          state: this.user_info.state.value,
          city: this.user_info.city.value,
          postCode: this.user_info.postcode.value,
          country: this.user_info.country.value,
          phoneNumber: this.user_info.phone_number.value,
          //purposeUsing: this.getPurpose(this.user_info.purpose_selected.value),
          captchaToken: await this.$recaptcha('login')
        };
        details.phoneNumber = details.phoneNumber.replace('+', '');
        details.info = this.order;
        // if (this.user_info.purpose_selected.value === 13) {
        //   details.purposeUsing = this.user_info.purpose_option.value;
        // }
        // console.log(details);
        this.loading = true;
        this.$store.dispatch('register', details)
            .then((data) => {
              console.log(data);
              if (data.status === 'error' && ['NOT_VALID_TFA', 'BAD_EMAIL'].indexOf(data.error.code) !== -1) {
                //we need get tfa to proceed with registration
                //1. Send TFA request
                if (data.error.code === 'BAD_EMAIL') {
                  this.loading = false;
                  this.show_neverbounce = true;
                  // Vue.$toast.open({
                  //     message: data.error.errors[0],
                  //     duration: 10000,
                  //     position: 'top-right',
                  //     type: 'error'
                  // });
                } else if (data.error.code === 'NOT_VALID_TFA') {
                  this.request_tfa().then((data) => {
                    this.loading = false;
                    if (data.status === 'success') {
                      Vue.$toast.open({
                        message: 'Code sent. Check Your mail box please.',
                        position: 'top-right',
                      });
                      this.show_tfa = true;
                    } else {
                      Vue.$toast.open({
                        message: 'Something went wrong. Please check all form fields properly filled',
                        position: 'top-right',
                        type: 'error'
                      });
                      this.show_tfa = true;
                    }
                  }).catch(() => {
                    this.loading = false;
                  });
                }
              } else {
                let i = 0;
                this.user_register_validation_errors.forEach((n) => {
                  i++;
                })
                if (i > 0) {
                  this.user_register_validation_errors.forEach((item) => {
                    if (this.user_info[item.property] !== undefined) {
                      if (item.property === 'email') {
                        this.user_info[item.property].errors.push("Email has wrong format or email already used")
                      } else {
                        this.user_info[item.property].errors.push(item.reason);
                      }

                    }
                  });
                  Vue.$toast.open({
                    message: 'Something went wrong. Please check all form fields properly filled',
                    position: 'top-right',
                    type: 'error'
                  });
                  this.loading = false;
                } else if (this.user !== null && this.user_register_validation_errors.length === 0) {
                  let orderDetails = {
                    productId: this.order.product.productId,
                    category: this.order.product.meta.category,
                    country: this.order.product.meta.country,
                    email: this.user_info.email.value,
                    amount: this.order.proxy_number,
                    billingCycle: this.order.period,
                    paymethod: this.user_info.paymethod.value,
                    paymethod_value: this.user_info.other_paymethod.value,
                  };
                  if (this.order.promo.length > 0 && this.order.promo_is_valid === true) {
                    orderDetails.promocode = this.order.promo;
                  }
                  if (this.order.package_type === 'IPv6') {
                    orderDetails.type = 'ipv6';
                    orderDetails.block = this.order.product.meta.ext.subnet;
                  }
                  if (this.order.subscription_type === 'trial') {
                    orderDetails.trial = 1;
                  }
                  console.log(orderDetails);
                  this.$store.dispatch('createOrder', orderDetails)
                      .then(() => {
                        Vue.$toast.open({
                          message: 'Your order successfully accepted.',
                          position: 'top-right',
                        });
                        this.loading = false;
                        this.doAuthByPost(
                            this.user_info.email.value,
                            this.user_info.password.value,
                            this.new_order.redirectUrl
                        );
                      })
                      .catch(() => {
                        Vue.$toast.open({
                          message: 'Something went wrong. Your order was not created',
                          position: 'top-right',
                          type: 'error'
                        });
                        this.loading = false;
                      });

                } else {
                  this.loading = false;
                }
              }

            })
            .catch(() => {
              this.loading = false;
              this.$toast.open({
                message: 'Something went wrong during order creation, please try later!',
                position: 'top-right',
                type: 'error'
              });
            });

      } else {
        this.$toast.open({
          message: 'Please enter all required values',
          position: 'top-right',
          type: 'error'
        });
        this.loading = false;
      }

    },
    getRedirectUrl(email, password, redirectSuccessUrl) {
      let arr = {
        'username': email,
        'password': password,
        'url': {
          'success': redirectSuccessUrl,
          'fail': redirectSuccessUrl
        },
        'text': {
          'pending': 'Opening the door...',
          'error': 'Error during signing .. '
        },
      };
      let data = JSON.stringify(arr);
      let encoded = window.btoa(data);
      let postfields = {'data': encoded};
      let uri = Env.whmcs_url + Env.easy_auth_pass + '?';
      let searchParameters = new URLSearchParams();

      Object.keys(postfields).forEach(function (parameterName) {
        searchParameters.append(parameterName, postfields[parameterName]);
      });

      return uri + searchParameters.toString();
    },
    applyPromo() {
      // Don't proceed if essential data is missing
      if (!this.order.product || !this.order.location || !this.order.proxy_type) {
        this.loading = false;
        // Reset promo display
        this.order.discount = 0;
        this.order.promo_is_valid = false;
        return;
      }

      // Only show toast notification if we're on the summary step
      const showNotification = this.step_element === 3;

      this.promoAlert = false;
      this.loading = true;
      let args = {
        productId: this.order.product.productId,
        amount: this.order.proxy_number,
        billingCycle: this.order.period,
        promocode: this.order.promo,
      };
      console.log(args);
      axios.post(Env.ab_url + '/api/be/calculate_total', args)
          .then(({data}) => {
            console.log(data);
            if (data.status == 'success') {
              if (data.discount !== undefined) {
                // Store the original price before discount
                this.order.subtotal = parseFloat(data.total).toFixed(2);
                this.order.discount = parseFloat(data.discount).toFixed(2);
                this.order.total = (data.total - data.discount).toFixed(2);
                
                if (this.order.promo.length > 0 && data.promoValid === false) {
                  this.promoAlert = true;
                  this.order.promo_is_valid = false;
                } else if (this.order.promo.length > 0 && data.promoValid === true) {
                  this.order.promo_is_valid = true;
                  if (showNotification) {
                    Vue.$toast.open({
                      message: 'Promo code applied',
                      position: 'top-right',
                    });
                  }
                } else if (this.order.promo.length == 0) {
                  Vue.$toast.open({
                    message: 'Promo code applied',
                    position: 'top-right',
                  });
                  this.order.promo_is_valid = true;
                }
              }
              this.loading = false;

            } else {
              this.loading = false;
              if (showNotification) {
                Vue.$toast.open({
                  message: 'Something went wrong. Price was not updated',
                  position: 'top-right',
                  type: 'error'
                });
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (showNotification) {
              Vue.$toast.open({
                message: 'Connection errors. Please try later',
                position: 'top-right',
                type: 'error'
              });
            }
          });
    },

    //----------------------------------------------------------------------------
    //end of requesting data via ajax and process data

    // start new methods to apply filters
    //----------------------------------------------------------------------------
    getProductsAffectedByFilters(country = true, proxy_type = true, period = true, trial = true, package_type = true) {
      this.filtered_products = this.products.filter((product) => {
        let p_type = this.order.package_type;
        if (this.order.package_type === '' || this.order.package_type === 'IPv4') p_type = 4;
        if (this.order.package_type === 'IPv6') p_type = 6;
        let condition = true;
        if (trial && this.order.subscription_type === 'trial') condition = condition && product.trial > 0;
        if (package_type && p_type !== '') condition = condition && product.meta.ipVersion == p_type;
        if (period && this.order.period !== '') condition = condition && this.productHasCycle(product);
        if (country && this.order.location !== '') condition = condition && product.meta.country === this.order.location;
        if (proxy_type && this.order.proxy_type !== '') condition = condition && product.meta.category === this.order.proxy_type;

        return condition;
      });
    },
    doAuthByPost(email, password, redirectSuccessUrl) {
      let arr = {
        'username': email,
        'password': password,
        'url': {
          'success': redirectSuccessUrl,
          'fail': redirectSuccessUrl
        },
        'text': {
          'pending': 'Opening the door...',
          'error': 'Error during signing .. '
        },
      };
      let data = JSON.stringify(arr);
      let encoded = window.btoa(data);
      let form = document.createElement('form');
      form.action = Env.whmcs_url + Env.easy_auth_pass;
      form.method = 'POST';
      form.innerHTML = '<input type="hidden" name="data" value="' + encoded + '">';
      document.body.append(form);
      form.submit();
    },
    affectAllFilters() {
      // Remove this line as it's resetting the promo:
      // this.resetPromoValues();
      
      this.countries = this.countries.map((item) => {
        item.show = true;
        return item;
      });
      this.periods = this.periods.map((item) => {
        item.show = true;
        return item;
      });
      this.proxy_types = this.proxy_types.map((item) => {
        item.show = true;
        return item;
      });

      //filter not related to chosen: trial, proxy_type, period
      this.getProductsAffectedByFilters(false);
      this.countries = this.countries.map((citem) => {
        let led = false;
        this.filtered_products.forEach((pitem) => {
          if (pitem.meta.country === citem.iso) led = true;
        });
        citem.show = led;

        return citem;
      });
      //filter not related to chosen: trial, country, period
      this.getProductsAffectedByFilters(true, false);
      this.proxy_types = this.proxy_types.map((titem) => {
        let led = false;
        this.filtered_products.forEach((pitem) => {
          if (pitem.meta.category === titem.name) {
            if(this.complex_ipv6_button.isSelected) {
              led = false;
            } else {
              led = true;
            }
          }
        });
        titem.show = led;

        return titem;
      });
      this.getProductsAffectedByFilters(true, true, false);
      this.periods = this.periods.map((ditem) => {
        let led = false;
        this.filtered_products.forEach((pitem) => {
          if (this.productHasGivenCycle(pitem, ditem.name)) led = true;
        });
        ditem.show = led;

        return ditem;
      });
      this.sortProxyTypeThenPutStaticRes();
    },
    productHasCycle(product) {
      return this.productHasGivenCycle(product, this.order.period);
    },
    productHasGivenCycle(product, period) {
      let result = false;
      if (product.pricingTiersForCycles !== undefined) {
        for (let cycle in product.pricingTiersForCycles) {
          if (cycle === period) result = true;
        }
      }

      return result;
    },
    setCountryChoice(iso) {
      this.countries = this.countries.map((c) => {
        c.show = true;
        if (c.iso === iso) {
          c.isSelected = true;

          if(c.iso !== 'us') {
            this.complex_ipv6_button.isDisabled = true;
          } else {
            this.complex_ipv6_button.isDisabled = false;
          }
          this.order.location_name = c.name;
        } else {
          c.isSelected = false;
        }
        return c;
      });
    },
    setCategoryChoice(name) {
      this.proxy_types = this.proxy_types.map((c) => {
        c.show = true;
        if (c.name === name) {
          c.isSelected = true;
        } else {
          c.isSelected = false;
        }
        return c;
      });
    },
    setPeriodChoice(period) {
      this.periods = this.periods.map((c) => {
        c.show = true;
        if (c.name === period) {
          c.isSelected = true;
        } else {
          c.isSelected = false;
        }
        return c;
      });
    },
    setPlanChoice(plan) {
      this.subscription_types = this.subscription_types.map((c) => {
        c.show = true;
        if (c.value === plan) {
          c.isSelected = true;
        } else {
          c.isSelected = false;
        }
        return c;
      });
    },
    setPlanChoiceWithUpdate(plan) {
      this.setPlanChoice(plan);
      this.order.subscription_type = plan;
      if (this.order.proxy_number < 25) this.order.proxy_number = 25;
    },
    setPackageTypeChoice(type) {
      this.package_types = this.package_types.map((c) => {
        c.show = true;
        if (c.title === type) {
          c.isSelected = true;
        } else {
          c.isSelected = false;
        }
        return c;
      });
    },
    getDialCodeByValue(value) {
      let code = '';
      this.country_items.forEach((c) => {
        if (c.value === value) code = c.dial_code;
      });

      return code;
    },
    changedDialCode() {
      let iso = '';
      this.country_items.forEach((c) => {
        if (c.value === this.user_info.country_phone_code.value) iso = c.code;
      });
      this.user_info.country.value = iso;
    },
    changedCountry() {
      let index = '';
      this.country_items.forEach((c) => {
        if (c.code === this.user_info.country.value) index = c.value;
      });
      this.user_info.country_phone_code.value = index;
    },
    goToOldIp6() {
      this.dialog_ip6 = !this.dialog_ip6;
      window.location.href = 'https://rayobyte.com/proxy/ipv6-proxies/';
    },
    poxyNumberValidated() {
      this.proxy_number_errors = [];
      if (this.order.package_type !== 'IPv6') {
        this.proxy_number_errors = validator.validate([{
          rule: 'minValue',
          option: 5
        }], this.order.proxy_number);
      }

      return this.proxy_number_errors.length === 0;
    },
    applyNew25Promo() {
      // Check if we already have the right category selected
      const hasCorrectCategory = this.order.proxy_type === 'static-res';
      
      // Only change country if we don't have any country selected
      if (!this.order.location) {
        this.order.location = 'us';
        this.order.location_name = 'United States';
        
        // Update the visual selection in the countries dropdown
        this.countries.forEach(country => {
          if (country.iso === 'us') {
            country.isSelected = true;
            this.countries_model = country;
          } else {
            country.isSelected = false;
          }
        });
      }
      
      // Only reset and update category if it's not already correct
      if (!hasCorrectCategory) {
        this.proxyTypesReset();
        this.proxy_types.forEach(type => {
          if (type.name === 'static-res') {
            type.isSelected = true;
            this.order.proxy_type = type.name;
          }
        });
      }
      
      // Set promo code
      this.order.promo = 'New25';
      this.promo_code_action.button = false;
      this.promo_code_action.input_content = true;
      
      // Calculate price and apply promo
      this.calculate_price();
    },
    applyNew15Promo() {
      // Check if we already have the right category selected
      const hasCorrectCategory = this.order.proxy_type === 'dedicated';
      
      // Only change country if we don't have any country selected
      if (!this.order.location) {
        this.order.location = 'us';
        this.order.location_name = 'United States';
        
        // Update the visual selection in the countries dropdown
        this.countries.forEach(country => {
          if (country.iso === 'us') {
            country.isSelected = true;
            this.countries_model = country;
          } else {
            country.isSelected = false;
          }
        });
      }
      
      // Only reset and update category if it's not already correct
      if (!hasCorrectCategory) {
        this.proxyTypesReset();
        this.proxy_types.forEach(type => {
          if (type.name === 'dedicated') {
            type.isSelected = true;
            this.order.proxy_type = type.name;
          }
        });
      }
      
      // Set promo code
      this.order.promo = 'New15';
      this.promo_code_action.button = false;
      this.promo_code_action.input_content = true;
      
      // Calculate price and apply promo
      this.calculate_price();
    },
    //----------------------------------------------------------------------------
    //end of new methods to apply filters

  },
  watch: {
    steps (val) {
      if (this.step_element > val) {
        this.step_element = val
      }
    },
    'user_info.country.value': function(newVal) {
      // Find the selected country in the countries_bulk array
      const selectedCountry = countries_bulk.find(country => country.code === newVal);

      // If the selected country does not have a states property, reset the state value
      if (!selectedCountry || !selectedCountry.states) {
        this.user_info.state.value = '';
      }
    }
  },
  computed: {
    country_items: function () {
      let result = [];
      countries_bulk.forEach(function (item, index) {
        result.push({
          code: item.code,
          text: item.name,
          value: index,
          dial_code: item.dial_code
        })
      });

      result.sort(function (item, nextItem) {
        if (item.dial_code > nextItem.dial_code) {
          return 1;
        }
        if (item.dial_code < nextItem.dial_code) {
          return -1;
        }

        return 0;
      });

      return result
    },
    home_country_items: function () {
      let result = [];
      countries_bulk.forEach(function (item, index) {
        let sanctionList = ['RU', 'IR', 'KP', 'CU', 'LY', 'SO', 'SD', 'CD', 'SY', 'YE', 'VE', 'BI', 'ZW', 'CF'];
        if(!sanctionList.includes(item.code)) {
          result.push({
            text: item.name,
            value: item.code,
          });
        }

      });

      return result
    },
    selectedCountry() {
      return countries_bulk.find(country => country.code === this.user_info.country.value);
    },
    ...mapState([
      'all_products',
      'availability',
      'new_order',
      'user',
      'user_register_validation_errors',
    ])
  },
  created() {
    // Check URL parameters when component is created
    const urlParams = new URLSearchParams(window.location.search);
    this.showPromoBlocks = urlParams.has('promo');
  },
  mounted() {

    // this.loading = true;
    // axios.post(Env.mvp_backend_pass + '/api/can_register')
    // 	.then(({data}) => {
    // 		this.loading = false;
    // 		if (data.result === 'success' && +data.total <= -1) {
    // 			window.location.href = Env.mvp_cart_page_pass + '/purchase';
    // 		}
    // 	}).catch(()=>{
    // 	this.loading = false;
    // });

    this.$store.dispatch('getAvailability').then((data) => {
      for (let country in this.availability) {
        let res = {};
        let dedi = 0;
        let semi = 0;
        for (let region_id in this.availability[country]) {
          dedi += 1 * this.availability[country][region_id].categories.dedicated;
          semi += 1 * this.availability[country][region_id].categories['semi-3'];
        }
        res.name = country;
        res['semi-3'] = semi;
        res.dedicated = dedi;
        this.available_locations[country] = res;
      }
    });
    this.$store.dispatch('getProducts').then(() => {

      this.products = this.all_products;
      if (this.$route.query.proposal !== 'best') {
        this.products = this.products.filter((item) => {
          // console.log(item.meta.category);
          return item.meta.category !== 'sneaker-static-res';
        });
      }
      this.get_available_products();
      this.loading = false;
      this.show_calculator = true;
      let countries = [];
      this.countries.forEach((c) => {
        countries.push(c.iso);
      });
      if (this.$route.query.country !== undefined && countries.indexOf(this.$route.query.country) !== -1) {
        this.order.location = this.$route.query.country;
        this.setCountryChoice(this.$route.query.country);
        this.affectAllFilters();
      }
      let categories = [];
      this.proxy_types.forEach((p) => {
        categories.push(p.name);
      });
      if (this.$route.query.category !== undefined && categories.indexOf(this.$route.query.category) !== -1) {
        this.order.proxy_type = this.$route.query.category;
        this.setCategoryChoice(this.$route.query.category);
        this.affectAllFilters();
      }
      let periods = [];
      this.periods.forEach((p) => {
        periods.push(p.name);
      });
      if (this.$route.query.billingCycle !== undefined && periods.indexOf(this.$route.query.billingCycle) !== -1) {
        this.order.period = this.$route.query.billingCycle;
        this.setPeriodChoice(this.$route.query.billingCycle);

      } else if (this.$route.query.billingCycle === undefined) {
        this.order.period = 'monthly';
        this.setPeriodChoice('monthly');
      }
      if (this.$route.query.quantity !== undefined && this.$route.query.quantity > 5) {
        this.order.proxy_number = this.$route.query.quantity;

      }
      // if (this.$route.query.trial !== undefined && ['0', '1'].indexOf(this.$route.query.trial) !== -1) {
      //     this.plan = this.$route.query.trial === '1' ? 'trial' : 'purchase';
      //     this.order.subscription_type = this.$route.query.trial === '1' ? 'trial' : 'purchase';
      //     this.setPlanChoice(this.order.subscription_type);
      // } else if (this.$route.query.trial === undefined) {
      //     this.plan = 'purchase';
      //     this.order.subscription_type = 'purchase';
      //     this.setPlanChoice(this.order.subscription_type);
      // }
      this.order.subscription_type = 'purchase';
      this.setPlanChoice('purchase');

      if (this.$route.query.ipv !== undefined && this.$route.query.ipv == 6) {
        this.order.package_type = 'IPv6';
        this.setPackageTypeChoice('IPv6');
        this.affectAllFilters();

      } else {
        this.order.package_type = 'IPv4';
        this.setPackageTypeChoice('IPv4');
      }

      if (Object.values(this.$route.query).length > 0) {
        this.calculate_price();
      }

      if (this.$route.query.proposal === 'best') {
        this.order.proxy_type = 'sneaker-static-res';
        this.setCategoryChoice('sneaker-static-res');
        this.order.location = 'us';
        this.setCountryChoice('us');
        this.order.period = 'monthly';
        this.setPeriodChoice('monthly');
        this.calculate_price();
      }

      this.setCountrySelectedFromQuery();

    }).catch(() => {
      this.loading = false;
      Vue.$toast.open({
        message: 'Something went wrong. Please reload page or try later.',
        position: 'top-right',
        type: 'error'
      })
    });

    for (let d = 1; d < 32; d++) this.fdays.push(d);
    for (let y = 2028; y > 1920; y--) this.years.push(y);
    this.days = this.fdays;
  }
}
</script>

<style scoped>
.remove-top-margin {
  margin-top: 0;
  padding-top: 0;
}
.custom-align-center {
  display: flex;
  justify-content: center;
}
.content-wrapper {
  padding: 0 8% 40px;
}
.context-wrapper {
  width: 100%;
  margin: 15px 0 0;
}
.context-wrapper h1 {
  font-weight: 800;
  font-size: 22px;
  line-height: 36px;
}


/* Step content begin */
.tab-item-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}
.tab-item-container.summary-tab {
  max-width: 65%;
}
/deep/ .tab-item-container .v-stepper__wrapper {
  width: 100%;
  padding-right: 1%;
  padding-bottom: 20px;
}
.tab-item-container .account-step-wrapper {
  padding-right: 10%;
}
.tab-item-container .section-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #505050;
}
.account-button-blocks {
  margin: 24px 0;
}
.account-button-blocks button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  align-items: center;
  color: #282828;
  padding: 10px 5px;
  width: 190px;
  height: 40px;
  margin-right: 16px;
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.15), rgba(7, 182, 191, 0.15)), #FFFFFF;
  border: 1px solid rgba(7, 182, 191, 0.3);
  border-radius: 4px;
}
.account-button-blocks a {
  width: 150px;
  height: 40px;
  background: #F6F7FB;
  border: 1px solid #F0F1F5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  display: inline-block;
  align-items: center;
  text-align: center;
  color: #282828;
}
.custom-input-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #505050;
  margin-left: 2px;
}
.custom-input-label span {
  color: #B4B4B4;
}
.check-terms-item {
  display: inline-block;
  width: 100%;
  height: 40px;
}
.check-terms-item .custom-checkbox {
  float: left;
  margin: 5px 0;
  position: relative;
}
/deep/ .check-terms-item .custom-checkbox label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
}
.check-terms-item .information-icon {
  line-height: 5px;
  font-size: 16px;
  height: 20px;
  align-items: center;
  cursor: pointer;
  display: inline-block;
  margin-left: 7px;
  margin-top: -16px;
}
.check-terms-item .terms-links {
  display: inline-block;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin-left: 7px;
  top: 10px;
  position: relative;
}
.check-terms-item .terms-links a {
  text-decoration: none;
  color: #07B6BF;
}
.check-terms-item .theme--light.v-icon:focus::after {
  opacity: 0;
}
/deep/ .check-terms-item .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: none;
}
/deep/ .check-terms-item .v-input__slot {
  margin-bottom: 0;
}
/deep/ .custom-checkbox .error--text .v-messages__wrapper {
  position: absolute;
  top: 3px;
  width: 350px;
}

.birthday-handler-wrapper {
  position: relative;
}
.modal-info-wrapper {
  position: relative;
  top: 14px;
  display: inline-flex;
}
.custom-tooltip-wrapper {
  background: none;
  opacity: 1 !important;
  color: #282828;
  margin: 19px 0 0 -12px;
}
.custom-left-tooltip-wrapper {
  background: none;
  opacity: 1 !important;
  color: #282828;
  margin: 20px 0 0 20px;
}

.custom-tooltip-block {
  width: 300px;
  height: 100px;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #E3E9ED;
}
.custom-tooltip-block::after {
  content: "";
  position: absolute;
  top: 10px;
  left: -4px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #E3E9ED transparent transparent;
}
.custom-left-tooltip-wrapper .custom-tooltip-block {
  width: 350px;
}
.custom-left-tooltip-wrapper .custom-tooltip-block::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -4px;
  left: auto;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #E3E9ED;
}

.custom-tooltip-block .date-item {
  height: 40px;
}
.custom-tooltip-block .date-custom-select {
  height: 40px;

}
/deep/ .date-custom-select .v-input__slot {
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.15), rgba(7, 182, 191, 0.15)), #FFFFFF !important;
  border: 1px solid rgba(7, 182, 191, 0.3);
  min-height: 40px;
  border-radius: 0;
}

/deep/ .birthday-handler-wrapper .v-input--is-disabled .theme--light.v-messages {
  color: #EE3889;
}
/deep/ .custom-checkbox .error--text {
  color: #EE3889 !important;
  caret-color: #EE3889 !important;
}

.note-modal-wrapper {
  padding: 10px 20px;
  top: -20px;
  position: relative;
  left: 35px;
  z-index: 100;
  background: none;
  box-shadow: none;
  width: 500px;
  height: 220px
}
.custom-note-content {
  box-shadow: none !important;
}
.note-modal-content {
  width: 445px;
  height: 200px;
  padding: 20px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #E3E9ED;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
}
.note-modal-content::after {
  content: "";
  position: absolute;
  top: 25px;
  left: -10px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent #E3E9ED transparent transparent;
}
.note-modal-content a {
  color: #07B6BF;
}
/deep/ .number-block .v-text-field__details {
  overflow: unset;
 }
/deep/ .number-block .custom-input-form .error--text {
  background: #fff;
  padding: 0 5px;
  min-width: 340px;
  margin-left: -15px;
}

.custom-input-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: none;
  border: 1px solid #E3E9ED;
  border-radius: 4px;
  height: 40px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding: 0;
}
.custom-input-form.error--text,
.custom-error-handler.error--text {
  border-color: #EE3889;
}
/deep/ .custom-input-form .error--text,
/deep/ .custom-select-country .error--text,
/deep/ .custom-error-handler .error--text {
  color: #EE3889 !important;
  caret-color: #EE3889 !important;
}
/deep/ .custom-input-form .v-input__slot:before,
/deep/ .custom-input-form .v-input__slot:after {
  border-style: hidden !important;
}
/deep/ .custom-input-form .theme--light.v-label {
  color: #B4B4B4 !important;
  padding: 3px 12px;
}
/deep/ .custom-input-form .v-text-field__slot input {
  padding: 0 12px;
  height: 40px;
  margin: 3px;
  line-height: 40px;
}
/deep/ .custom-input-form .v-input__slot {
  margin-bottom: 5px;
}
.custom-error-handler {
  margin-top: -14px;
  padding: 0;
}
/deep/ .custom-error-handler .v-input__slot {
  display: none;
}
/deep/ .custom-error-handler .v-input__slot:before {
  border-width: 0;
}
/deep/ .custom-error-handler.error--text .v-input__slot:before {
  border-top: 1px solid;
}

.number-section {
  width: 26px;
  height: 26px;
  background: #F6F7FB;
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  margin-right: 8px;
  display: inline-block;
  text-align: center;
}
.section-support-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #07B6BF;
  margin-left: 8px;
  text-transform: initial;
}
.category-list-wrapper {
  margin: 11px 0 20px;
  justify-content: flex-start;
}
.category-list-wrapper .btn-category-wrapper {
  margin-right: 16px;
}
.category-list-wrapper .btn-category-wrapper:last-child {
  margin-right: 0;
}
.category-list-wrapper .v-btn {
  border-radius: 4px;
  padding: 10px 14px;
  height: 40px;
  background: #F6F7FB;
  border: 1px solid #F0F1F5;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: initial;
}
.category-list-wrapper .v-btn.theme--dark {
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.15), rgba(7, 182, 191, 0.15)), #FFFFFF;
  border: 1px solid rgba(7, 182, 191, 0.3);
  font-weight: 700;
  color: #282828;
}
.custom-error-wrapper {
  display: none;
  margin-top: -17px;
  padding: 2px 0;
}
.custom-error-wrapper.error--text {
  display: block;
}
.custom-error-wrapper .error-message {
  color: #EE3889;
}

.custom-select-country {
  margin: 10px 0 20px;
  width: 50%;
  height: 50px;
  position: relative;
}
/deep/ .custom-select-country.v-text-field--outlined fieldset {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}
/deep/ .custom-select-country .v-input__slot {
  min-height: 50px;
  margin-bottom: 0;
}
/deep/ .custom-select-country .v-select__selections {
  padding: 10px 0 0 !important;
}

.custom-select-country .option-country-wrapper {
  display: inline-block;
  height: 40px;
}
.option-country-wrapper .flag-icon {
  display: inline-block;
  margin: 0 5px;
  width: 24px;
  line-height: 27px;
}

/deep/ .v-list-item:has(.custom-list-style) {
  width: 100%;
  padding: 0;
}
.custom-list-style {
  height: 50px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #F0F1F5;
  border-radius: 4px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.custom-list-style:hover {
  box-shadow: none;
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.15), rgba(7, 182, 191, 0.15)), #FFFFFF;
  border: 1px solid rgba(7, 182, 191, 0.3);
  border-radius: 4px;
  font-weight: 700;
}
.custom-list-style .flag-icon {
  display: inline-flex;
  width: 24px;
  float: left;
  justify-content: left;
  align-items: center;
  margin-right: 8px;
}
.custom-list-style .v-list-item__title {
  line-height: 22px;
}
.number-block-wrapper {
  margin-bottom: 20px;
  position: relative;
}
.number-block-wrapper .number-block {
  width: 100%;
}
.number-block-wrapper .custom-input-form {
  display: inline-block;
  width: 65%;
  padding: 3px 12px;
}
/deep/ .number-block-wrapper .custom-input-form .v-text-field__slot input {
  padding: 0;
  margin: 0;
  line-height: 20px;
}
.number-block-wrapper .information-icon {
  display: inline-block;
  width: 25px;
  color: #07B6BF;
  margin: 0 10px;
  font-size: 30px;
  cursor: pointer;
  position: relative;
}
.number-block-wrapper .information-icon.theme--light.v-icon:focus::after {
  opacity: 0;
}
.period-list-wrapper {
  margin: 10px 0 20px;
}
.period-list-wrapper .period-item {
  display: inline-block;
  margin-right: 16px;
}
.period-list-wrapper .period-item:last-child {
  margin-right: 0;
}
.period-item .period-btn {
  padding: 10px 16px;
  height: 40px;
  background: #F6F7FB;
  border: 1px solid #F0F1F5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-transform: lowercase;
  text-indent: 0;
}
.period-item .period-btn.v-btn.theme--dark {
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.15), rgba(7, 182, 191, 0.15)), #FFFFFF;
  border: 1px solid rgba(7, 182, 191, 0.3);
  font-weight: 700;
  color: #282828;
}
.period-item .discount-val {
  color: #EE3889;
  margin-left: 5px;
}

.custom-align-center .v-stepper__items {
  justify-content: center;
  display: flex;
}

.summary-block-wrapper .section-title {
  margin: 5px 0;
}
.summary-order-items .v-list-item {
  min-height: 30px;
  padding: 0;
}
.summary-order-items .v-list-item__content {
  padding: 6px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #505050;
  opacity: 0.6;
}
.summary-order-items .v-list-item__content.justify-end {
  color: #282828;
  opacity: 1;
}

.have-promo-btn {
  color: #07B6BF;
  margin: 20px 0;
}
.promo-code-wrapper {
  margin: 15px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.promo-code-wrapper .apply-promo-btn {
  padding: 12px 20px;
  width: 89px;
  min-width: 89px !important;
  flex: none;
  height: 40px;
  background: #07B6BF;
  border: 2px solid #07B6BF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
}
.promo-code-wrapper .apply-promo-input {
  padding: 10px 12px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E9ED;
  border-radius: 4px;
}
.error-promo-wrapper {
  position: relative;
  display: block;
  width: 100%;
}
.promo-list-item {
  width: 60%;
}
.promo-list-item .apply-promo-input {
  width: 100%;
}
.error-promo-wrapper .promo-error-block {
  height: 40px;
  background: rgba(238, 56, 137, 0.09) !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 8px 12px;
}
/deep/ .error-promo-wrapper .v-icon {
  font-size: 14px;
  margin: 0;
}
/deep/ .error-promo-wrapper .warning--text,
/deep/ .error-promo-wrapper .v-icon.warning--text {
  color: #EE3889 !important;
  caret-color: #EE3889 !important;
}

.order-result-item {
  margin: 0;
}
.order-result-item .v-list-item__content {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #505050;
  opacity: 0.6;
}
.order-result-item .v-list-item__content.justify-end {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #282828;
}

.payment-block-wrapper {
  margin: 22px 0;
}
.payment-btn-wrapper {
  margin: 16px 0;
}
.payment-btn-wrapper .payment-item {
  padding: 0 16px 0 0;
}
.payment-btn-wrapper .payment-item:last-child {
  padding-right: 0;
}
.payment-btn-wrapper .payment-item .v-btn {
  padding: 12px 24px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
}
.payment-btn-wrapper .payment-item .v-btn.theme--dark,
.payment-btn-wrapper .payment-item .v-btn:hover::before,
.payment-btn-wrapper .payment-item .v-btn:visited::before {
  background: linear-gradient(0deg, rgba(7, 182, 191, 0.05), rgba(7, 182, 191, 0.05)), #FFFFFF;
  border: 1px solid #07B6BF;
  color: #282828;
}
.payment-btn-wrapper .payment-item .v-btn.theme--dark {
  opacity: 1;
}
.payment-btn-wrapper .payment-item .v-btn img {
  margin-right: 10px;
}

.billing-info-wrapper .section-title {
  margin: 8px 0;
}
.billing-info-wrapper .address-section-wrapper {
  margin: 24px 0 -10px;
}
  /* Step content end */



.b-input {
  padding:       2px 8px;
  border:        1px solid #D5E0F4;
  border-radius: 4px;
  width:         100%;
  font-size:     1rem;
  height:        2.25em;
  box-shadow:    inset 0 0 0.1em rgba(0, 0, 0, 0.5);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top:      20px;
}

.show-tfa-modal {
  width: 60%;
}

.bd-paddings {
  padding: 0 6px 0 0;
}

@media screen and (min-width: 960px) {

  .proxy_type_text {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 1264px) {

  .proxy_type_text {
    font-size: 12px !important;
  }

}

@media screen and (max-width: 1264px) {
  .sticky-sidebar-wrapper {
    padding: 20px !important;
  }
  .stepper-wrapper .stepper-block .v-stepper__step {
    padding: 10px;
  }
}
@media screen and (max-width: 960px) {
  .sticky-sidebar-wrapper {
    padding: 5px !important;
  }
}

.availability-note {
  width:      100%;
  max-height: 60px;
}

.check-ipv6 {
  padding:       25px 15px;
  margin-top:    10px;
  border-radius: 2px;
  border:        1px solid red;
}

.check-ipv6 span {
  font-size: 16px;
  color:     red;
}

/* Stepper begin */
.stepper-wrapper {
  width: 100%;
  margin: 0;
}
.stepper-wrapper .stepper-block {
  background: none;
}
/*/deep/ .stepper-block  .stepper-header .v-stepper__step:hover {*/
/*  background: linear-gradient(0deg, rgba(7, 182, 191, 0.05), rgba(7, 182, 191, 0.05)), #FFFFFF;*/
/*}*/
.stepper-block  .stepper-header {
  box-shadow: none;
  display: inline-flex;
}
.stepper-block  .stepper-header .step-item {
  color: #B4B4B4;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
/deep/ .stepper-block  .stepper-header .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: none !important;
  border: 1px solid;
  color: #B4B4B4;

}
.stepper-block  .stepper-header .v-stepper__step--active .step-item {
  font-weight: 700;
  color: #282828;
}
.stepper-block  .stepper-header .v-stepper__step:first-child {
  padding-left: 0;
}
.stepper-btn-wrapper {
  margin: 40px 0;
}
.stepper-wrapper .step-btn {
  border-radius: 4px;
  color: #505050;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: initial;
  text-transform: none;
  height: 48px !important;
}
.stepper-wrapper .next-step-btn {
  float: right;
  padding: 12px 12px 12px 20px !important;
  background: #07B6BF;
  color: #FFFFFF;
}
.stepper-wrapper .prev-step-btn {
  text-align: center;
  padding: 12px 20px;
  border: 1px solid #D0D0D0;
  background: transparent;
  box-shadow: none;
}
.stepper-wrapper .prev-step-btn .v-icon {
  font-size: 14px;
  margin-right: 5px;
}
.order-btn-wrapper {
  display: flex;
}
.order-btn-wrapper .stepper-btn-wrapper {
  display: inline-block;
  margin: 0 30px 0 0;
}
.order-btn-wrapper .order-btn {
  display: inline-block;
  height: 48px !important;
  border-radius: 4px;
  min-width: auto !important;
}

.sticky-sidebar-wrapper {
  background: #ffff;
  padding: 50px;
}
.sticky-sidebar-wrapper .order-result-item {
  margin-top: 15px;
}
.sticky-sidebar-wrapper .font-weight-bold {
  margin-bottom: 7px;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

.tfa-block-wrapper {
  padding: 18px;
}
.tfa-block-wrapper .tfa-icon-wrapper {
  width: 100%;
  text-align: center;
}
.tfa-icon-wrapper img {
  width: 70px;
}
.tfa-block-wrapper .tfa-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin: 5px 0 10px;
}
.tfa-block-wrapper .tfa-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.tfa-btn-wrapper {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tfa-btn-wrapper .tfa-btn-items {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.tfa-btn-wrapper .confirm-btn {
  padding: 8px 16px;
  width: 91px;
  height: 40px;
  background: #07B6BF;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff !important;
  text-transform: none;
  letter-spacing: initial;
}
.tfa-btn-wrapper .resend-btn {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #07B6BF;
  text-transform: none;
  letter-spacing: initial;
}
.tfa-btn-wrapper .custom-close-modal {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #07B6BF;
  cursor: pointer;
}

.custom-select-form {
  padding: 5px 12px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E3E9ED;
  border-radius: 4px;
}
/deep/ .custom-select-form.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}
/* Stepper end */

.neverbounce-alet-wrapper {
  padding: 20px 10px;
  color: #EE3889;
  overflow: hidden;
}
.neverbounce-alet-wrapper .information-icon {
  display: flex;
  font-size: 40px;
  text-align: center;
  color: #EE3889;
}
.neverbounce-alet-wrapper .headline {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #EE3889;
}
.neverbounce-alet-wrapper .alert-content {
  text-align: left;
  color: #EE3889 !important;
}

@media only screen and (max-width: 1248px) {
  .tab-item-container.summary-tab {
    max-width: 70%;
  }
}
@media only screen and (max-width: 1148px) {
  .tab-item-container.summary-tab {
    max-width: 85%;
  }
}
@media screen and (max-width: 960px) {
  .sticky-holder {
    padding: 20px 10% !important;
  }
  .error-promo-wrapper .promo-error-block {
    padding: 8px 5px;
  }
}
@media screen and (max-width: 750px) {
  .tab-item-container.summary-tab {
    max-width: 100%;
  }
}

.new25-promo-btn {
  margin: 10px 0;
  text-transform: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  height: 48px;
  letter-spacing: normal;
}

.text-success {
  color: #07B6BF !important;
}

.text-discount {
  color: #07B6BF !important;
}

.summary-order-items .v-list-item__content {
  padding: 6px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.order-result-item .v-list-item__content {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.promo-buttons-wrapper {
  margin: 20px 0;
  padding: 0 24px;
}

.promo-blocks-wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
}

.promo-block {
  flex: 1;
  padding: 24px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px; /* Matching other blocks' rounded corners */
}

.promo-block:first-child {
  background: #4169E1; /* Royal blue background */
  color: white;
}

.promo-block:last-child {
  background: #FDB813; /* Orange/yellow background */
}

.promo-block:first-child h2 {
  color: white;
}

.promo-block:last-child h2 {
  color: #000;
}

.promo-block h2 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.3;
  padding: 0 24px;
}

.coupon-code {
  background: white;
  padding: 4px 12px;
  border-radius: 4px;
  border: 2px dashed #1976D2;
  font-weight: bold;
  color: #000;
}

.countdown-wrapper {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.countdown-item {
  text-align: center;
}

.countdown-item .number {
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
}

.promo-block:first-child .countdown-item .number,
.promo-block:first-child .countdown-item .label {
  color: #000;
}

.promo-block:last-child .countdown-item .number,
.promo-block:last-child .countdown-item .label {
  color: white;
}

.countdown-item .label {
  font-size: 12px;
  margin-top: 4px;
}

.promo-btn {
  padding: 12px 32px;
  height: auto;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  border-radius: 50px;
  background: white !important;
  color: #07B6BF !important; /* Using the green color from "What is it?" */
  border: 2px solid #07B6BF !important; /* Adding green border */
  box-shadow: none;
  transition: all 0.3s ease;
}

.promo-btn:hover {
  background: #07B6BF !important;
  color: white !important;
  transform: none;
}

@media (max-width: 959px) {
  .promo-blocks-wrapper {
    flex-direction: column;
  }
  
  .promo-block {
    width: 100%;
  }
}
</style>
